import { render, staticRenderFns } from "./partNotesSectionGpi.vue?vue&type=template&id=6f0c647b&"
import script from "./partNotesSectionGpi.vue?vue&type=script&lang=js&"
export * from "./partNotesSectionGpi.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/apps/jenkins/workspace/AEM_DEV_Publish_6.5/aem-base/content/src/main/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6f0c647b')) {
      api.createRecord('6f0c647b', component.options)
    } else {
      api.reload('6f0c647b', component.options)
    }
    module.hot.accept("./partNotesSectionGpi.vue?vue&type=template&id=6f0c647b&", function () {
      api.rerender('6f0c647b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "content/jcr_root/apps/fmmp-base/components/content/driv-part-finder/driv-part-detail-page/clientlibs/src/partNotesSectionGpi.vue"
export default component.exports