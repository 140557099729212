export const FIELDS = {
    APPLICATION_TYPE: "application_type",
    MANUFACTURER: "engine_mfrs",
    BASE: "engine_bases",
    USAGE: "engine_usages",
    VEHICLE: "vehicle_types",
    PERFORMANCE_MFRS: "performance_mfrs",
    PERFORMANCE_BASE: "performance_bases",
    HEAVY_DUTY_MFRS: "heavy_duty_mfrs",
    HEAVY_DUTY_BASE: "heavy_duty_bases",

};

const getInitialFieldConfig = (placeholder) => ({
    items: [],
    model: "",
    placeholder,
    disabled: true,
    loading: false,
});

export const INITIAL_FIELDS_CONFIG = {
    [FIELDS.APPLICATION_TYPE]: getInitialFieldConfig("Application type"),
    [FIELDS.MANUFACTURER]: getInitialFieldConfig("Manufacturer"),
    [FIELDS.PERFORMANCE_MFRS]: getInitialFieldConfig("Manufacturer"),
    [FIELDS.PERFORMANCE_BASE]: getInitialFieldConfig("Base"),
    [FIELDS.BASE]: getInitialFieldConfig("Base"),
    [FIELDS.USAGE]: getInitialFieldConfig("Usage"),
    [FIELDS.VEHICLE]: getInitialFieldConfig("Vehicle Type"),
    [FIELDS.HEAVY_DUTY_MFRS]: getInitialFieldConfig("Manufacturer"),
    [FIELDS.HEAVY_DUTY_BASE]: getInitialFieldConfig("Base"),
};

export const EMPTY_ITEM = {
    value: "",
    label: "---",
}
