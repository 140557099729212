<template>
    <div class="applications-subtab tab-table">
        <table class="tab-table desktop">
            <thead>
                <tr>
                    <th v-for="column in columns" :key="column.column">
                        {{ getTranslation(column.column) }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(product, i) in tabsData" :key="i" class="product">
                    <td
                        v-for="column in columns"
                        :class="`product__${column.class}`"
                        :key="column.column"
                    >
                        {{
                            transformToMissedValue(
                                checkIfPropExistInPassengerCarTab(column, product),
                            )
                        }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="tab-table mobile">
            <div v-for="(product, i) in tabsData" :key="i" class="product">
                <div
                    v-for="column in columns"
                    :class="`product__${column.class}`"
                    :key="column.column"
                >
                    <span>{{ getTranslation(column.column) }}:</span>
                    {{ transformToMissedValue(checkIfPropExistInPassengerCarTab(column, product)) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { checkIfPropExistInPassengerCarTab } from "../../../common/partDetailsPage.helpers";
import {
    getTranslation,
    transformToMissedValue,
} from "../../../../common/partFinderCorporate.helpers";
import { PROPS } from "../../../common/partDetailsPage.constants";

export default Vue.component("driv-part-detail-applications-heavy-duty", {
    data() {
        return {
            columns: [
                { column: "Engine Manufacturer", property: PROPS.ENGINE_MFR, class: "engine-mfr" },
                { column: "Engine Base", property: PROPS.ENGINE_BASE_VALUE, class: "engine-base" },
                {
                    column: "Engine Version",
                    property: PROPS.ENGINE_VERSION,
                    class: "engine-version",
                },
                { column: "Description", property: PROPS.CATEGORY, class: "description" },
                { column: "Position", property: PROPS.POSITION, class: "position" },
                {
                    column: "Application Quantity",
                    property: PROPS.QUANTITY,
                    class: "vehicle-quantity",
                },
            ],
        };
    },
    props: {
        tabsData: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        getTranslation,
        transformToMissedValue,
        checkIfPropExistInPassengerCarTab,
    },
});
</script>
