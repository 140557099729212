<template>
    <div class="parts-result-filters_wrapper">
        <div class="ymm-search-results_filters-container">
            <div class="panel-head">
                <p class="panel-head__title">
                    <fmmp-i18n :text="filtersTitle" />
                </p>
                <button class="button-main panel-head__button" @click="resetFilters">
                    <fmmp-i18n text="Clear Filters" />
                </button>
            </div>
            <div class="panel-body">
                <driv-section
                    v-show="brands && brands.length"
                    :title="getTranslation('Brand / Sub-Brand')"
                    :isInitiallyOpened="
                        isAnyStaticFilterChecked(filtersLevels.BRANDS, filtersLevels.SUB_BRANDS)
                    "
                >
                    <driv-nested-group
                        :options="validatedBrands"
                        :onChange="onChangeNestedGroup"
                        :value="state"
                    />
                </driv-section>

                <driv-section
                    v-show="categories && categories.length"
                    :title="getTranslation('Product Category')"
                    :isInitiallyOpened="
                        isAnyStaticFilterChecked(
                            filtersLevels.CATEGORIES,
                            filtersLevels.SUB_CATEGORIES,
                        )
                    "
                >
                    <driv-nested-group
                        :options="validatedCategories"
                        :onChange="onChangeNestedGroup"
                        :value="state"
                    />
                </driv-section>

                <driv-section
                    v-show="validatedPartTypes && validatedPartTypes.length"
                    :title="getTranslation('Part Types / Positions')"
                    :isInitiallyOpened="
                        isAnyStaticFilterChecked(
                            partTypesLevels.PART_TYPES,
                            partTypesLevels.POSITIONS,
                        )
                    "
                >
                    <driv-nested-group
                        :options="validatedPartTypes"
                        :onChange="onChangeNestedGroup"
                        :value="state"
                        :enableSearch="isPartTypesSearchAvailable"
                        :resetSearch="resetSearch"
                        :onSearchReset="onSearchReset"
                    />
                </driv-section>

                <driv-section
                    v-for="(config, i) in sub_configs"
                    :key="i + config.name"
                    :title="config.name"
                    :isInitiallyOpened="!!(state[config.type] && state[config.type].length)"
                >
                    <part-filters-checkbox-group
                        :options="config.values"
                        :onChange="onChangeCheckboxGroup"
                        :value="state"
                        :type="config.type"
                    />
                </driv-section>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapCategoriesToOptions,
    normalizeCheckedValues,
    mapPartTypesToOptions,
    getUniqueValuesFromArrays,
    getPropertyFoundById,
    FILTERS_LEVELS,
    PART_TYPES_LEVELS,
    PART_TYPES_IDS_PROPERTY,
    ENABLED_PROPERTY_NAME,
    parseFiltersToState,
    mapBrandsToOptions,
    getRelatedValidGroups,
    CATEGORIES_IDS_PROPERTY,
} from "./helpers.js";
import { getTranslation } from "../../../common/partFinderCorporate.helpers";

export default Vue.component("driv-parts-result-filters", {
    data() {
        return {
            categories: [],
            sub_configs: [],
            partTypes: [],
            brands: [],
            state: {
                enabled: {},
            },
            validGroups: [],
            validPartTypes: [],
            validCategories: [],
            validFilters: {
                categories: [],
                sub_categories: [],
                brands: [],
                sub_brands: [],
            },
            resetSearch: false,
            filtersLevels: FILTERS_LEVELS,
            partTypesLevels: PART_TYPES_LEVELS,
        };
    },
    props: {
        filtersTitle: {
            type: String,
            default: "Filter Results",
        },
        onFilter: Function,
        filters: {
            type: Object,
            default: () => ({
                categories: [],
                configs: {},
                partTypes: [],
                brands: [],
            }),
        },
        initFilters: Object,
        validFiltersData: Object, // structure is the same as for this.initFilters
        ignoreValidGroups: {
            type: Boolean,
            default: false,
        },
        isPartTypesSearchAvailable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        validatedPartTypes() {
            // disabled part types that are not valid for selected categories/sub categories
            // enabled all partTypes if no categories/sub categories selected
            return this.partTypes.map((partType) => {
                partType.disabled = !!(
                    this.validPartTypes.length && !this.validPartTypes.includes(partType.id)
                );
                return partType;
            });
        },
        validatedCategories() {
            return this.getValidatedNestedFilters({
                filterKey: FILTERS_LEVELS.CATEGORIES,
                subFilterKey: FILTERS_LEVELS.SUB_CATEGORIES,
                filters: this.categories,
                validFilterIds: this.validCategories,
            });
        },
        validatedBrands() {
            return this.getValidatedNestedFilters({
                filterKey: FILTERS_LEVELS.BRANDS,
                subFilterKey: FILTERS_LEVELS.SUB_BRANDS,
                filters: this.brands,
            });
        },
    },
    methods: {
        isAnyStaticFilterChecked(prop, subProp) {
            // vcdb attrs are not included
            return !!Object.entries(this.state).find(([key, value]) => {
                if (Array.isArray(value) || key === ENABLED_PROPERTY_NAME) return;
                return value[prop]?.length || value[subProp]?.length;
            });
        },
        onChangeNestedGroup(field, id, checked, childNames, parentGroupId) {
            let partTypesIds = [];
            let categoriesIds =
                getPropertyFoundById({
                    array: this.brands,
                    id,
                    property: CATEGORIES_IDS_PROPERTY,
                }) || [];

            // sub category has composed id, that contains _ symbol
            const [categoryId, subCategoryId] = id.split("_");

            const isCategoryOrSubcategory =
                field === FILTERS_LEVELS.CATEGORIES || field === FILTERS_LEVELS.SUB_CATEGORIES;
            const isBrand = field === FILTERS_LEVELS.BRANDS;

            // if category/sub category is checked, need to disable invalid part types
            if (isCategoryOrSubcategory) {
                // if selected sub category or unselected not the last sub category, apply this
                if (
                    subCategoryId &&
                    (checked || this.state[categoryId][FILTERS_LEVELS.SUB_CATEGORIES]?.length > 1)
                ) {
                    partTypesIds = this.getSubCategoryValidPartTypes({
                        categoryId,
                        subCategoryId,
                    });

                    // if sub category has been selected, then need to remove category's valid part
                    // types, and leave only valid for sub category
                    // applies this only if first sub category in this category is selected
                    if (!this.state[categoryId][FILTERS_LEVELS.SUB_CATEGORIES]?.length > 0) {
                        const categoryPartTypesIds = this.getCategoryValidPartTypes(categoryId);

                        this.validPartTypes = this.validPartTypes.filter(
                            (partTypeId) => !categoryPartTypesIds?.includes(partTypeId),
                        );
                    }
                    // if unselected the last sub category, need to strikethrough part types,
                    // that are invalid for selected category
                } else {
                    partTypesIds = this.getCategoryValidPartTypes(categoryId);
                }
            }

            if (checked) {
                if (!!this.state[parentGroupId]?.[field]) {
                    this.state[parentGroupId][field].push(id);
                } else {
                    let obj = { [field]: [id] };
                    let copiedObj = Object.assign({}, this.state[parentGroupId], obj);
                    this.$set(this.state, parentGroupId, copiedObj);
                }
                // if checked and categoriesIds.length, add categoriesIds to validCategories
                if (isBrand && categoriesIds?.length) {
                    this.validCategories = getUniqueValuesFromArrays(
                        this.validCategories,
                        categoriesIds,
                    );
                }
                // if checked and partTypesIds.length, add partTypesIds to validPartTypes
                if (partTypesIds.length) {
                    this.validPartTypes = getUniqueValuesFromArrays(
                        this.validPartTypes,
                        partTypesIds,
                    );
                }
            } else {
                this.state[parentGroupId][field] = this.state[parentGroupId][field]?.filter(
                    (item) => item !== id,
                );

                childNames?.forEach((key) => {
                    this.state[parentGroupId][key] = this.state[parentGroupId][key]?.filter(
                        (item) => {
                            if (item.length > id.length) return !item.startsWith(id);

                            return !id.startsWith(item);
                        },
                    );
                });

                // if unchecked and partTypesIds.length
                if (partTypesIds.length) {
                    // ...and if it was unchecked the last subcategory,
                    // apply valid part types for category
                    if (
                        subCategoryId &&
                        !this.state[categoryId][FILTERS_LEVELS.SUB_CATEGORIES]?.length > 0
                    ) {
                        this.validPartTypes = getUniqueValuesFromArrays(
                            this.validPartTypes,
                            partTypesIds,
                        );
                        // ...remove partTypesIds from validPartTypes
                    } else {
                        this.validPartTypes = this.validPartTypes.filter(
                            (partTypeId) => !partTypesIds?.includes(partTypeId),
                        );
                    }
                }

                // if unchecked and categoriesIds.length
                if (isBrand && categoriesIds.length) {
                    const checkedBrandsCategoriesIds = Object.entries(this.state).reduce(
                        (acc, [key, values]) => {
                            if (Array.isArray(values) || key === ENABLED_PROPERTY_NAME) return acc;
                            if (values.brands?.length) {
                                const catIds =
                                    getPropertyFoundById({
                                        array: this.brands,
                                        id: key,
                                        property: CATEGORIES_IDS_PROPERTY,
                                    }) || [];
                                return getUniqueValuesFromArrays(acc, catIds);
                            }
                            return acc;
                        },
                        [],
                    );
                    categoriesIds = categoriesIds.filter(
                        (id) => !checkedBrandsCategoriesIds.includes(id),
                    );
                    // ...remove categoriesIds from validCategories
                    this.validCategories = this.validCategories.filter(
                        (catId) => !categoriesIds?.includes(catId),
                    );
                }
            }

            // reset checked state for all categories / sub categories,
            // that are not valid for selected brand
            if (isBrand) {
                Object.keys(this.state).forEach((item) => {
                    if (Array.isArray(this.state[item]) || item === ENABLED_PROPERTY_NAME) return;
                    if (!this.validCategories?.includes(Number(item))) {
                        this.state[item] = {
                            ...this.state[item],
                            categories: [],
                            sub_categories: [],
                        };
                    }
                });
                this.updateValidPartTypesForValidCategories();
            }
            // reset checked state for all part types and positions,
            // that are not valid for selected category/subcategory
            if (isCategoryOrSubcategory || isBrand) {
                Object.keys(this.state).forEach((item) => {
                    if (!this.validPartTypes?.includes(Number(item))) {
                        if (Array.isArray(this.state[item])) return;

                        this.state[item] = {
                            ...this.state[item],
                            part_type: [],
                            position: [],
                        };
                    }
                });
            }

            this.onFilter(normalizeCheckedValues(this.state, this.ignoreValidGroups));
        },
        updateValidPartTypesForValidCategories(state) {
            let partTypesToAdd = [];
            let validCategories = this.validCategories;
            if (state) {
                // get actually checked categories, applies only on init render, if preselected filters
                const checkedCategories = Object.entries(state || {}).reduce(
                    (acc, [key, values]) => {
                        if (values.categories?.length) {
                            return [...acc, Number(key)];
                        }
                        return acc;
                    },
                    [],
                );
                validCategories = checkedCategories;
            }
            // go through valid categories and fetch valid part types for each
            validCategories.forEach((catId) => {
                // if subCategories exist, do not fetch part types for categories
                if (this.state[catId] && this.state[catId].sub_categories?.length) {
                    this.state[catId].sub_categories.forEach((subCatId) => {
                        partTypesToAdd = getUniqueValuesFromArrays(
                            partTypesToAdd,
                            this.getSubCategoryValidPartTypes({
                                categoryId: catId,
                                subCategoryId: subCatId.split("_")[1],
                            }) || [],
                        );
                    });
                    return;
                }

                partTypesToAdd = getUniqueValuesFromArrays(
                    partTypesToAdd,
                    this.getCategoryValidPartTypes(catId) || [],
                );
            });
            this.validPartTypes = partTypesToAdd;
        },
        onChangeCheckboxGroup(field, id, checked) {
            let checkedValues = this.state[field] || [];

            if (checked) {
                checkedValues.push(id);
            } else {
                checkedValues = checkedValues.filter((item) => item !== id);
            }
            this.$set(this.state, field, checkedValues);

            this.updateValidGroups();

            this.onFilter(normalizeCheckedValues(this.state, this.ignoreValidGroups));
        },
        updateValidGroups() {
            const filteredCheckedValues = this.getFilteredCheckedValues();
            if (Object.values(filteredCheckedValues).every((values) => !values.length)) {
                this.$set(this.state, ENABLED_PROPERTY_NAME, {});
                return;
            }

            const availableValues = getRelatedValidGroups({
                validGroups: this.validGroups,
                checkedValues: filteredCheckedValues,
            });

            this.$set(this.state, ENABLED_PROPERTY_NAME, availableValues);
        },
        resetFilters() {
            this.state = {
                enabled: {},
            };
            this.validPartTypes = [];
            this.validCategories = [];
            this.validFilters = {
                categories: [],
                sub_categories: [],
                brands: [],
                sub_brands: [],
            };
            this.onFilter(normalizeCheckedValues(this.state, this.ignoreValidGroups));
            this.resetSearch = true;
        },
        getFilteredCheckedValues() {
            return Object.entries(this.state).reduce((acc, [field, value]) => {
                if (Array.isArray(value)) {
                    acc[field] = value;
                }
                return acc;
            }, {});
        },
        getTranslation,
        getCategoryValidPartTypes(categoryId) {
            return getPropertyFoundById({
                array: this.categories,
                id: `${categoryId}`,
                property: PART_TYPES_IDS_PROPERTY,
            });
        },
        getSubCategoryValidPartTypes({ categoryId, subCategoryId }) {
            const subCategories = getPropertyFoundById({
                array: this.categories,
                id: categoryId,
                property: FILTERS_LEVELS.SUB_CATEGORIES,
            });

            return getPropertyFoundById({
                array: subCategories,
                id: subCategoryId,
                property: PART_TYPES_IDS_PROPERTY,
            });
        },
        updateValidCategories(validState) {
            const state = validState || this.state;
            if (
                !Object.entries(state).some(([_, group]) => {
                    return group.brands?.length;
                })
            ) {
                this.validCategories = [];
                return;
            }
            const categoriesIds = Object.entries(state).reduce((acc, [key, values]) => {
                if (key === ENABLED_PROPERTY_NAME || Array.isArray(values)) return acc;

                const { brands } = values;

                return [
                    ...acc,
                    ...(getPropertyFoundById({
                        array: this.brands,
                        id: key,
                        property: CATEGORIES_IDS_PROPERTY,
                    }) || []),
                ];
            }, []);

            this.validCategories = getUniqueValuesFromArrays(this.validCategories, categoriesIds);
        },
        updateValidPartTypes(validState) {
            const state = validState || this.state;
            if (
                !Object.entries(state).some(([key, group]) => {
                    return group.categories?.length || group.sub_categories?.length;
                })
            ) {
                this.validPartTypes = [];
                return;
            }
            const partTypesIds = Object.entries(state).reduce((acc, [key, values]) => {
                if (key === ENABLED_PROPERTY_NAME || Array.isArray(values)) return acc;

                const { sub_categories } = values;

                if (sub_categories?.length) {
                    return sub_categories.reduce((subCategoryAcc, subCategory) => {
                        return [
                            ...subCategoryAcc,
                            ...(this.getSubCategoryValidPartTypes({
                                categoryId: key,
                                subCategoryId: subCategory.split("_")[1],
                            }) || []),
                        ];
                    }, []);
                }

                return [...acc, ...(this.getCategoryValidPartTypes(key) || [])];
            }, []);

            this.validPartTypes = getUniqueValuesFromArrays(this.validPartTypes, partTypesIds);
        },
        updateValidNestedFilters({ filterKey, subFilterKey, shouldUpdate, validState }) {
            let validFilterIds = [];
            let validSubFilterIds = [];

            if (shouldUpdate) {
                Object.entries(validState).forEach(([key, group]) => {
                    if (group[filterKey] || group[subFilterKey]) {
                        validFilterIds = [...validFilterIds, ...(group[filterKey] || [])];
                        validSubFilterIds = [...validSubFilterIds, ...(group[subFilterKey] || [])];
                    }
                }, {});
            }

            this.$set(this.validFilters, filterKey, validFilterIds);
            this.$set(this.validFilters, subFilterKey, validSubFilterIds);
        },
        isCategorySelected(group) {
            return group.part_type?.length || group.position?.length || group?.length;
        },
        shouldUpdateValidCategories(validState) {
            return Object.entries(validState).some(([key, group]) => {
                return this.isCategorySelected(group);
            });
        },
        shouldUpdateValidBrands(validState) {
            return Object.entries(validState).some(([key, group]) => {
                return (
                    this.isCategorySelected(group) ||
                    group.categories?.length ||
                    group.sub_categories?.length
                );
            });
        },
        getValidatedNestedFilters({ filterKey, subFilterKey, filters, validFilterIds = [] }) {
            if (!filters) return [];
            return filters.map((filter) => {
                if (
                    (this.validFilters[filterKey]?.length &&
                        !this.validFilters[filterKey]?.includes(`${filter.id}`)) ||
                    (validFilterIds.length && !validFilterIds.includes(Number(filter.id)))
                ) {
                    filter.disabled = true;
                } else {
                    filter.disabled = false;
                }

                filter.options.map((subFilter) => {
                    if (
                        this.validFilters[subFilterKey]?.length &&
                        !this.validFilters[subFilterKey]?.some((item) => {
                            const [catId, subCatId] = item.split("_");
                            return catId === `${filter.id}` && subCatId === `${subFilter.id}`;
                        })
                    ) {
                        subFilter.disabled = true;
                    } else {
                        subFilter.disabled = false;
                    }
                });
                return filter;
            });
        },
        onSearchReset() {
            this.resetSearch = false;
        },
    },
    created() {
        this.categories = mapCategoriesToOptions(this.filters.categories || []);
        this.partTypes = mapPartTypesToOptions(this.filters.partTypes || []);
        this.brands = mapBrandsToOptions(this.filters.brands || []);
        this.sub_configs = this.filters.configs?.sub_configs;
        this.validGroups = this.filters.configs?.valid_groups;

        if (this.initFilters) {
            this.state = {
                ...this.state,
                ...parseFiltersToState(this.initFilters),
            };
            this.updateValidGroups();
            this.updateValidCategories();
            this.updateValidPartTypes();
        }
    },
    watch: {
        filters: {
            handler: function (newFilters, oldFilters) {
                if (_.isEqual(newFilters, oldFilters)) return;

                this.categories = mapCategoriesToOptions(newFilters.categories);
                this.partTypes = mapPartTypesToOptions(newFilters.partTypes);
                this.brands = mapBrandsToOptions(newFilters.brands);
                this.sub_configs = newFilters.configs?.sub_configs;
                this.validGroups = newFilters.configs?.valid_groups;
            },
            deep: true,
        },
        validFiltersData: {
            handler: function (val) {
                if (!val) return;
                const validState = {
                    ...this.state,
                    ...parseFiltersToState(val),
                };
                this.updateValidNestedFilters({
                    filterKey: FILTERS_LEVELS.CATEGORIES,
                    subFilterKey: FILTERS_LEVELS.SUB_CATEGORIES,
                    shouldUpdate: this.shouldUpdateValidCategories(validState),
                    validState,
                });
                this.updateValidNestedFilters({
                    filterKey: FILTERS_LEVELS.BRANDS,
                    subFilterKey: FILTERS_LEVELS.SUB_BRANDS,
                    shouldUpdate: this.shouldUpdateValidBrands(validState),
                    validState,
                });
                this.updateValidPartTypesForValidCategories(validState);
            },
            deep: true,
        },
    },
});
$("input[type='checkbox']").change(function() {
	var checkboxLabel = $(this).next('.panel-body_title').text();

        dataLayer.push({

        event:"filter_change",
		filter_name: checkboxLabel

        })

});
</script>

