<template>
    <div class="driv-part-results-no-results">
        <h4 class="no-results">
            <fmmp-i18n
                text="We were unable to find a part using that number. Try searching with our Parts Finder below:"
            />
        </h4>
        <driv-gpi-update-case-btn
            v-if="noPartsFound"
            @onClick="sendPartsData"
            :isReadOnly="isReadOnlyVersionGpi"
        >
        </driv-gpi-update-case-btn>
    </div>
</template>

<script>
import { getQsParams, mapPartToSalesforceObj } from "../helpers";

export default Vue.component("driv-empty-part-results-page", {
    props: {
        noPartsFound: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        sendPartsData() {
            const partsData = [mapPartToSalesforceObj({ part: null })];

            Vue.CatalogApi.CatalogApiService.sendPartsToSalesforce(partsData)
                .then((res) => {
                    window.Sfdc?.canvas?.client?.publish(res, {
                        name: "GPI.refresh",
                        payload: { status: "Completed" },
                    });
                })
                .catch((err) => console.error(err));
        },
    },
    computed: {
        isReadOnlyVersionGpi() {
            const { signed_request } = getQsParams();
            return !signed_request;
        },
    },
    created() {
        $(".driv-refine-search-container").hide();
        $(".driv-part-finder-corporate").show();
    },
});
</script>
