<template>
    <div class="driv-part-detail-page-applications-container">
        <driv-tabs class="driv-part-detail-page-applications" :isTabToScroll="true">
            <template v-for="tab in tabsToDisplay">
                <driv-tab
                    :name="getTranslation(tab.label)"
                    :selected="true"
                    v-if="tab.data"
                    :key="tab.label"
                >
                    <component :is="generateTabContent(tab.value)" :tabsData="tab.data"></component>
                </driv-tab>
            </template>
        </driv-tabs>
        <div class="driv-part-detail-page-applications mobile-view">
            <div class="category-selection-block">
                <fmmp-i18n text="Category" class="category" />
                <fmmp-autocomplete
                    class="driv-part-common applications-subtabs-options"
                    :config="autocompleteConfig"
                    :placeholder="getTranslation('Select option...')"
                    :items="tabsToDisplay"
                    v-model="stateInDropdown"
                ></fmmp-autocomplete>
            </div>
            <component
                :is="generateTabContent(stateInDropdown.value)"
                :tabsData="stateInDropdown.data"
            ></component>
        </div>
    </div>
</template>

<script>
import {
    PART_DETAIL_APPLICATIONS,
    PART_DETAIL_APPLICATIONS_SUBTABS,
} from "../../../common/partDetailsPage.constants";
import { getTranslation } from "../../../../common/partFinderCorporate.helpers";

export default Vue.component("driv-part-detail-page-applications", {
    props: {
        apiTabs: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            tabsToDisplay: null,
            stateInDropdown: null,
            autocompleteConfig: {
                minLength: 0,
                delay: 0,
                html: false,
                maxHeight: "300px",
                onlySelectValid: true,
                searchOnFocus: false,
            },
        };
    },
    methods: {
        getTranslation,
        generateTabContent(tabApiField) {
            const commonPart = "driv-part-detail-applications-";
            switch (tabApiField) {
                case PART_DETAIL_APPLICATIONS.VEHICLE.id:
                    return `${commonPart}passenger-car`;
                case PART_DETAIL_APPLICATIONS.COMMERCIAL_UNDUSTRIAL_AG.id:
                    return `${commonPart}commercial`;
                case PART_DETAIL_APPLICATIONS.POWERSPORT.id:
                    return `${commonPart}powersport`;
                case PART_DETAIL_APPLICATIONS.SMALL_ENGINE.id:
                case PART_DETAIL_APPLICATIONS.LAWN_AND_GARDEN.id:
                    return `${commonPart}small-engine`;
                case PART_DETAIL_APPLICATIONS.MARINE.id:
                    return `${commonPart}marine`;
                case PART_DETAIL_APPLICATIONS.PERFORMANCE.id:
                    return `${commonPart}performance`;
                case PART_DETAIL_APPLICATIONS.HEAVY_DUTY.id:
                    return `${commonPart}heavy-duty`;
                default:
                    return;
            }
        },
        mapAndFilterTabsToDisplay() {
            const apiTabsNames = Object.keys(this.apiTabs).map(
                (name) => name.replace(/  +/g, " "), // remove double spaces from api tab names
            );

            this.tabsToDisplay = PART_DETAIL_APPLICATIONS_SUBTABS.reduce((acc, tab) => {
                const tabName = apiTabsNames.find(
                    (name) => tab.value.indexOf(name) !== -1 || name.indexOf(tab.value) !== -1,
                );
                const tabData = this.apiTabs[tabName]?.applications || [];

                if (!tabData.length) return acc;
                return [...acc, { ...tab, data: tabData }];
            }, []);
        },
    },
    created() {
        this.mapAndFilterTabsToDisplay();
        this.stateInDropdown = this.tabsToDisplay.length && this.tabsToDisplay[0];
    },
});
</script>
