import "./partDetailPage.vue";
import "./partDetailPageCarousel.vue";
import "./partDetailPageHeader.vue";
import "./partDetailPageContent.vue";
import "./part-detail-tabs/partDetailPageSpecifications.vue";
import "./part-detail-tabs/partDetailPageApps.vue";
import "./part-detail-tabs/partDetailPageAppsPassengerCar.vue";
import "./part-detail-tabs/partDetailPageAppsPerformance.vue";
import "./part-detail-tabs/partDetailPageAppsCommercial.vue";
import "./part-detail-tabs/partDetailPageAppsSmallEngine.vue";
import "./part-detail-tabs/partDetailPageAppsMarine.vue";
import "./part-detail-tabs/partDetailPageAppsPowersport.vue";
import "./part-detail-tabs/partDetailPageAppsHeavyDutyEngine.vue";
import "./part-detail-tabs/partDetailOtherMedia.vue";
import "./part-detail-tabs/partDetailContents.vue";
import "./partDetailPageLogo.vue";
import "./partListModal.vue";
import "./partNotesSectionGpi.vue";

if (document.querySelector('[data-component="driv-part-detail-page"]')) {
    new Vue({
        el: '[data-component="driv-part-detail-page"]',
    });
}
