var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "part-notes-section" }, [
    _c(
      "div",
      { staticClass: "part-notes-header" },
      [
        _c("fmmp-i18n", {
          staticClass: "part-notes-header__title",
          attrs: { text: "Part notes" }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "part-notes" },
      _vm._l(_vm.partNotes, function(partNote, i) {
        return _c("div", { key: i, staticClass: "part-note" }, [
          _c("div", { staticClass: "column note-column" }, [
            _c(
              "div",
              { staticClass: "column__title" },
              [_c("fmmp-i18n", { attrs: { text: "Note" } })],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "column__value" }, [
              _c("span", { domProps: { innerHTML: _vm._s(partNote.note) } })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "column details-column" }, [
            _c(
              "div",
              { staticClass: "column__title" },
              [_c("fmmp-i18n", { attrs: { text: "Note details" } })],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "column__value" }, [
              _c(
                "div",
                { staticClass: "metadata" },
                [
                  _c("fmmp-i18n", { attrs: { text: "Created by: " } }),
                  _vm._v(
                    "\n                        " +
                      _vm._s(partNote.createdBy) +
                      "\n                    "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "metadata" },
                [
                  _c("fmmp-i18n", { attrs: { text: "Created date: " } }),
                  _vm._v(
                    "\n                        " +
                      _vm._s(partNote.createdDate) +
                      "\n                    "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "metadata" },
                [
                  _c("fmmp-i18n", { attrs: { text: "Updated by: " } }),
                  _vm._v(
                    "\n                        " +
                      _vm._s(partNote.lastModifiedBy) +
                      "\n                    "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "metadata" },
                [
                  _c("fmmp-i18n", { attrs: { text: "Update date: " } }),
                  _vm._v(
                    "\n                        " +
                      _vm._s(partNote.lastModifiedDate) +
                      "\n                    "
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                "column additional-info-column",
                { "hidden-mobile": !partNote.files.length }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "column__title" },
                [_c("fmmp-i18n", { attrs: { text: "Additional info" } })],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "column__value" }, [
                _c(
                  "div",
                  { staticClass: "files" },
                  _vm._l(partNote.files, function(file) {
                    return _c(
                      "div",
                      { key: file.content_version_id, staticClass: "file" },
                      [
                        _vm.isPdfDocument(file)
                          ? _c("i", { staticClass: "fa-solid fa-file-pdf" })
                          : _c("i", { staticClass: "fa-solid fa-file" }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "document-url",
                            attrs: {
                              id: file.content_version_id,
                              target: "_blank",
                              rel: "noreferrer noopener",
                              type: _vm.getBlobTypeForFile(file)
                            },
                            on: {
                              click: function($event) {
                                return _vm.onPartNoteFileClick(file)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(file.name) +
                                "\n                            "
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }