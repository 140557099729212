var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "part-detail-header-logo" }, [
    _vm.imageExist
      ? _c("img", {
          attrs: { src: _vm.brand_image_url, alt: _vm.partDetail.brand_name },
          on: { error: _vm.fallbackBrandName }
        })
      : _c("div", { staticClass: "part-detail-header-logo_placeholder" }, [
          _c("span", [_vm._v(_vm._s(_vm.partDetail.brand_name))])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }