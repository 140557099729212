export const PART_FINDER_CORPORATE_SEARCH_TYPES = {
    VIN: "vin",
    LICENSE_PLATE: "license-plate",
    ENGINE: "engine",
    VEHICLE: "vehicle",
    SPECIFICATION: "specification",
    PART_NUMBER_SEARCH: "part-number-search",
    ENGINE_PERFORMANCE: "performance",
    ENGINE_HEAVY_DUTY: "heavy",
    TORQUE: "torque",
};

export const PART_FINDER_CORPORATE_TABS = [
    {
        value: PART_FINDER_CORPORATE_SEARCH_TYPES.VEHICLE,
        label: "Vehicle",
    },
    {
        value: PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE,
        label: "Engine",
    },
    {
        value: PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE,
        label: "Performance",
    },
    {
        value: PART_FINDER_CORPORATE_SEARCH_TYPES.LICENSE_PLATE,
        label: "License plate",
    },
    {
        value: PART_FINDER_CORPORATE_SEARCH_TYPES.VIN,
        label: "Vin",
    },
    {
        value: PART_FINDER_CORPORATE_SEARCH_TYPES.SPECIFICATION,
        label: "Specification",
    },
    {
        value: PART_FINDER_CORPORATE_SEARCH_TYPES.PART_NUMBER_SEARCH,
        label: "Part Number / Interchange",
    },
];

export const WTB_EVENTS = {
    install: "click-get-it-installed",
    store: "click-buy-in-store-brand",
};

export const APPLICATION_TYPE_KEYS = {
    label: "applicationType",
    value: "groupId",
};

export const BRAND_TYPE_KEYS = {
    label: "brandName",
    value: "brandCode",
};

export const VIN_LP_VEHICLE_TYPE = "2,8";
export const SPECIFICATION_PARTS = "11";
export const SPECIFICATION_TORQUE = "22";
