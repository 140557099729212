var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "applications-subtab marine-tab" },
    _vm._l(_vm.tabsData, function(product, i) {
      return _c("div", { key: i, staticClass: "product" }, [
        _c("div", { staticClass: "product__manufacturer" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.transformToMissedValue(product.mfr && product.mfr.value)
              ) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product__model" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.transformToMissedValue(
                  product.equipment_model && product.equipment_model.value
                )
              ) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product__engine" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.transformToMissedValue(
                  product.engine_base && product.engine_base.engine_base_value
                )
              ) +
              "\n        "
          )
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }