var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "applications-subtab tab-table" }, [
    _c("table", { staticClass: "tab-table desktop" }, [
      _c("thead", [
        _c(
          "tr",
          _vm._l(_vm.columns, function(column) {
            return _c("th", { key: column.column }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.getTranslation(column.column)) +
                  "\n                "
              )
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.tabsData, function(product, i) {
          return _c(
            "tr",
            { key: i, staticClass: "product" },
            _vm._l(_vm.columns, function(column) {
              return _c(
                "td",
                { key: column.column, class: "product__" + column.class },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.transformToBlankSpace(
                          _vm.checkIfPropExistInPassengerCarTab(column, product)
                        )
                      ) +
                      "\n                "
                  )
                ]
              )
            }),
            0
          )
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tab-table mobile" },
      _vm._l(_vm.tabsData, function(product, i) {
        return _c(
          "div",
          { key: i, staticClass: "product" },
          _vm._l(_vm.mobileColumns, function(column) {
            return _c(
              "div",
              { key: column.column, class: "product__" + column.class },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.getTranslation(column.column)) + ":")
                ]),
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.transformToMissedValue(
                        _vm.checkIfPropExistInPassengerCarTab(column, product)
                      )
                    ) +
                    "\n            "
                )
              ]
            )
          }),
          0
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }