<template>
    <div :class="[
                'driv-diagram-results-carousel',
                    {
                        'corporate': isCorporate,
                    },
                ]">
        <vue-slick-carousel
            class="driv-diagram-results-carousel__nav-container"
            v-bind="navSettings"
            v-if="carouselImages.length"
        >
            <div
                v-for="(item, index) in carouselImages"
                :key="item.file_name"
                :class="['slide', { 'selected-slide': selectedSlideIndex === index }]"
                @click="() => onSlideSelect(index)"
            >
                <div class="card-container">
                    <div class="slide-image" v-html="item.svg"></div>
                    <span class="title"
                        v-if="item.desc" 
                        v-html="item.desc"></span>
                    <button class="button-main">{{ btnText(index) }}</button>
                </div>
            </div>
        </vue-slick-carousel>
        <div class="driv-diagram-results-carousel__images-container">
            <div
                v-html="selectedSlide.svg"
                :key="selectedSlide.file_name"
                class="image"
                :id="selectedSlide.file_name"
                @click="(e) => toggleModal(e, selectedSlide.file_name)"
            ></div>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import { getTranslation } from "../../../../common/partFinderCorporate.helpers";

const CAROUSEL_REFS = {
    NAV: "nav",
};

export default Vue.component("driv-diagram-results-carousel", {
    components: { VueSlickCarousel },
    data() {
        return {
            navSettings: {
                ref: CAROUSEL_REFS.NAV,
                asNavFor: null,
                slidesToShow: 5,
                arrows: true,
                dots: true,
                responsive: [
                    {
                        breakpoint: 1600,
                        settings: {
                            slidesToShow: 4,
                        },
                    },
                    {
                        breakpoint: 1300,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            arrows: false,
                        },
                    },
                ],
            },
            selectedSlideIndex: null,
            isCorporate: window.brand === 'corporate' ? true : false,
        };
    },
    computed: {
        selectedSlide() {
            return this.carouselImages[this.selectedSlideIndex] || {};
        },
    },
    props: {
        carouselImages: {
            type: Array,
            default: () => [],
        },
        toggleModal: {
            type: Function,
            default: () => {},
        },
        handleDiagramSelect: {
            type: Function,
            default: () => {},
        },
    },
    methods: {
        btnText(index) {
            return this.selectedSlideIndex === index
                ? getTranslation("selected")
                : getTranslation("select");
        },
        onSlideSelect(id) {
            this.selectedSlideIndex = id;

            this.$nextTick(function () {
                if (this.selectedSlide.file_name) {
                    this.handleDiagramSelect(this.selectedSlide.file_name);
                }
            });
        },
    },
});
</script>
