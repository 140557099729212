<template>
    <fmmp-modal
        v-if="partListModal"
        rootClass="driv-part-list-modal part-list-modal"
        @close="closeModal"
    >
        <template slot="icon"><span></span><span></span></template>
        <template slot="title">
            <fmmp-i18n text="My parts list" />
        </template>
        <div>
            <div class="modal-list-controllers no-print">
                <button class="print-btn" @click="printList">
                    <fmmp-i18n text="print list" />
                </button>
                <button
                    v-if="!disableAddToCart"
                    class="add-btn"
                    @click="handleAddToCart"
                    :class="[isAdded ? 'add-btn-active' : '']"
                >
                    <fmmp-i18n v-if="isAdded" text="added to cart" />
                    <fmmp-i18n v-else text="add to cart" />
                </button>
                <button class="clear-btn" @click="clearList">
                    <fmmp-i18n text="clear list" />
                </button>
            </div>
            <div class="container">
                <table>
                    <thead>
                        <tr>
                            <th class="th" v-for="(value, i) in tableDataHeader" :key="i">
                                {{ getTranslation(value) }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(part, index) in tableData" :key="index">
                            <td class="td">{{ part.brand_name }}</td>
                            <td class="td">{{ part.part_number }}</td>
                            <td class="td">{{ part.part_type }}</td>
                            <td class="td">
                                <input
                                    type="number"
                                    min="1"
                                    :placeholder="part.quantity"
                                    v-model="part.quantity"
                                    @change="updateQuantity(index, part.quantity)"
                                />
                            </td>
                            <td class="fa fa-trash" @click="removeItemFromList(index)"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </fmmp-modal>
</template>
<script>
import {
    createOverlay,
    removeOverlay,
    getTranslation,
} from "../../../common/partFinderCorporate.helpers";
import { LOCAL_STORAGE_PROPERTIES } from "./constants";
const ADD_TO_CART_URL = "/fmstorefront/federalmogul/en/USD/fme-cat-cart/entries/add";
const ADD_TO_CART_MAP = [
    {
        label: (i) => `entryList[${i}].entryNumber`,
        value: (_, i) => i,
    },
    {
        label: (i) => `entryList[${i}].product.code`,
        value: (part) => `${part.brand_code || ""}${part.part_number}`,
    },
    {
        label: (i) => `entryList[${i}].quantity`,
        value: (part) => part.quantity,
    },
    {
        label: (i) => `entryList[${i}].product.manufacturer`,
        value: (part) => part.brand_code || "",
    },
    {
        label: (i) => `entryList[${i}].product.url`,
        value: (part) => part.imageUrl,
    },
];

export default Vue.component("driv-part-list-modal", {
    props: {
        partListModal: {
            type: Boolean,
            default: () => false,
        },
        disableAddToCart: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isAdded: false,
            toPrint: false,
            tableDataHeader: [
                "Brand",
                "part number",
                "Description",
                "qty.",
                "", // column for trash icon
            ],
            tableData: null,
        };
    },
    watch: {
        partListModal(val) {
            if (val) {
                createOverlay(this.closeModal);
                const partsList = localStorage[this.partsListKey];
                this.tableData = partsList ? JSON.parse(partsList) : [];
            } else {
                removeOverlay();
            }
        },
    },
    methods: {
        getTranslation,
        updateQuantity(index, newValue) {
            const partsListKey = this.partsListKey;
            const updatedData = JSON.parse(localStorage[partsListKey]).map((el, i) =>
                i === index ? { ...el, quantity: newValue } : el,
            );
            localStorage[partsListKey] = JSON.stringify(updatedData);
        },
        removeItemFromList(index) {
            this.tableData.splice(index, 1);
            localStorage[this.partsListKey] = JSON.stringify(this.tableData);
        },
        clearList() {
            this.tableData = [];
            localStorage[this.partsListKey] = this.tableData;
        },
        printList() {
            Vue.Global.Analytics.trackEvent("parts-list", "click-print-list", null, null, {
                callback: function () {
                    window.print();
                },
            });
        },
        closeModal() {
            this.$emit("updatePartListModal", false);
        },
        handleAddToCart() {
            const eventParams = [];
            const form = document.createElement("form");
            form.method = "post";
            form.action = ADD_TO_CART_URL;
            form.setAttribute("modelAttribute", "cartForm");

            this.tableData.forEach((part, i) => {
                ADD_TO_CART_MAP.forEach((item) => {
                    const elem = document.createElement("input");
                    elem.setAttribute("type", "hidden");
                    elem.setAttribute("name", item.label(i));
                    elem.setAttribute("value", item.value(part, i));
                    form.appendChild(elem);
                });

                eventParams.push(part.part_number);
            });

            document.body.appendChild(form);

            Vue.Global.Analytics.trackEvent(
                "parts-list",
                "click-add-to-cart",
                eventParams.join("|"),
                null,
                {
                    callback: function () {
                        form.submit();
                    },
                },
            );
        },
    },
    computed: {
        partsListKey() {
            return this.disableAddToCart
                ? LOCAL_STORAGE_PROPERTIES.LOCAL_PARTS_LIST
                : LOCAL_STORAGE_PROPERTIES.ADD_TO_CART_PARTS_LIST;
        },
    },
});
</script>
