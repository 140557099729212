<template>
    <div>
        <div v-show="enableSearch" class="nested-group__search-wrapper">
            <input
                :placeholder="getTranslation('Filter')"
                class="nested-group__search-input"
                v-model="searchString"
            />
        </div>
        <div v-for="option in filteredOptions" :key="option.id" class="nested-group-wrapper">
            <driv-checkbox
                :disabled="option.disabled"
                :onChange="
                    (e) =>
                        onChange(
                            option.levelName,
                            getId(option),
                            e.target.checked,
                            option.childNames,
                            option.parentGroupId,
                        )
                "
                :checked="isChecked(option, value)"
            >
                <span class="panel-body_title">{{ option.value }}</span>
            </driv-checkbox>

            <group
                v-if="nestedCondition(option, value)"
                :options="option.options"
                :value="value"
                :onChange="onChange"
            />
        </div>
        <div v-show="isEmptyGroupShown" class="nested-group__empty">
            <fmmp-i18n text="Nothing is found" />
        </div>
    </div>
</template>

<script>
export default Vue.component("driv-nested-group", {
    name: "group",
    data() {
        return { timeout: null, debouncedSearchString: "" };
    },
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Object,
            default: () => {},
        },
        onChange: {
            type: Function,
        },
        enableSearch: {
            type: Boolean,
            default: false,
        },
        resetSearch: Boolean,
        onSearchReset: {
            type: Function,
            default: () => {},
        },
    },
    computed: {
        isEmptyGroupShown() {
            return this.enableSearch && !this.filteredOptions.length;
        },
        filteredOptions() {
            if (this.enableSearch && this.debouncedSearchString) {
                return this.options.filter((partType) => {
                    return partType.value.toLowerCase().includes(this.debouncedSearchString);
                });
            }

            return this.options;
        },
        searchString: {
            get() {
                return this.debouncedSearchString;
            },
            set(val) {
                if (this.timeout) clearTimeout(this.timeout);

                this.timeout = setTimeout(() => {
                    this.debouncedSearchString = val.toLowerCase().trim();
                }, 500);
            },
        },
    },
    methods: {
        nestedCondition(option, value) {
            if (!value[option.parentGroupId]) return false;

            if (!value[option.parentGroupId][option.levelName]) return false;

            if (!value[option.parentGroupId][option.levelName].includes(this.getId(option)))
                return false;

            return !!option.options;
        },
        isChecked(option, value) {
            if (!value[option.parentGroupId]) return false;

            if (!value[option.parentGroupId][option.levelName]) return false;

            return value[option.parentGroupId][option.levelName].includes(this.getId(option));
        },
        getId(option) {
            return option.composedId || option.id;
        },
        getTranslation(value) {
            return Vue.i18n.get(value);
        },
    },
    watch: {
        resetSearch: {
            handler: function (newVal) {
                if (newVal) {
                    this.debouncedSearchString = "";
                    this.onSearchReset();
                }
            },
            immediate: true,
        },
    },
});
</script>
