var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "applications-subtab commercial-tab" },
    _vm._l(_vm.tabsData, function(product, i) {
      return _c("div", { key: i, staticClass: "product" }, [
        _c("div", { staticClass: "car__make" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.transformToMissedValue(product.mfr && product.mfr.value)
              ) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "car__model" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.transformToMissedValue(
                  product.equipment_model && product.equipment_model.value
                )
              ) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "car__years" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.transformToMissedValue(
                  product.production_years &&
                    _vm.displayYearsRange(product.production_years)
                )
              ) +
              "\n        "
          )
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }