var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "driv-part-detail-page-applications-container" },
    [
      _c(
        "driv-tabs",
        {
          staticClass: "driv-part-detail-page-applications",
          attrs: { isTabToScroll: true }
        },
        [
          _vm._l(_vm.tabsToDisplay, function(tab) {
            return [
              tab.data
                ? _c(
                    "driv-tab",
                    {
                      key: tab.label,
                      attrs: {
                        name: _vm.getTranslation(tab.label),
                        selected: true
                      }
                    },
                    [
                      _c(_vm.generateTabContent(tab.value), {
                        tag: "component",
                        attrs: { tabsData: tab.data }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "driv-part-detail-page-applications mobile-view" },
        [
          _c(
            "div",
            { staticClass: "category-selection-block" },
            [
              _c("fmmp-i18n", {
                staticClass: "category",
                attrs: { text: "Category" }
              }),
              _vm._v(" "),
              _c("fmmp-autocomplete", {
                staticClass: "driv-part-common applications-subtabs-options",
                attrs: {
                  config: _vm.autocompleteConfig,
                  placeholder: _vm.getTranslation("Select option..."),
                  items: _vm.tabsToDisplay
                },
                model: {
                  value: _vm.stateInDropdown,
                  callback: function($$v) {
                    _vm.stateInDropdown = $$v
                  },
                  expression: "stateInDropdown"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(_vm.generateTabContent(_vm.stateInDropdown.value), {
            tag: "component",
            attrs: { tabsData: _vm.stateInDropdown.data }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }