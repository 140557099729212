<template>
    <div class="driv-part-detail-page-content">
        <div class="description-text"><fmmp-i18n text="Description" /></div>
        <div v-if="warningText" class="part-detail-warning">
            <i class="fal fa-exclamation-triangle">
                <span class="triangle-lining"></span>
            </i>
            <p class="part-detail-warning_text">{{ warningText }}</p>
        </div>
        <div class="part-detail-content-characteristic">
            <p class="part-detail-content-characteristic_title">
                {{ fab.title }}
            </p>
            <ul class="part-detail-content-characteristic_list">
                <li v-for="option in fab.content" :key="option.content">
                    {{ option.content }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { warningAttrValue, warningAttrNoValue } from "../../common/partDetailsPage.constants";

const TYPE_CODES = {
    MARKETING: "MKT",
    FEATURES_AND_BENEFITS: "FAB",
};

export default Vue.component("driv-part-detail-page-content", {
    props: {
        partDetail: Object,
    },
    data() {
        return {
            warning: null,
        };
    },
    computed: {
        fab() {
            const FAB = { title: "", content: [] };

            this.partDetail.descriptions?.forEach((data) => {
                switch (data.type_code) {
                    case TYPE_CODES.MARKETING:
                        FAB.title = data.contents[0].content;
                        break;
                    case TYPE_CODES.FEATURES_AND_BENEFITS:
                        FAB.content = data.contents;
                        break;
                    default:
                        break;
                }
            });

            return FAB;
        },
        warningText() {
            this.partDetail.part_attributes?.forEach((attr) => {
                if (attr.attribute === warningAttrValue && attr.attribute_value.toLowerCase() !== warningAttrNoValue.toLowerCase() && attr.attribute_value !== '') {
                    this.warning = attr.attribute_value;
                }
            });
            return this.warning;
        },
    },
});
</script>
