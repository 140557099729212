<template>
    <div v-if="isLoading" class="three-quarters-loader"></div>
    <div v-else class="driv-part-detail-page">
        <div class="driv-part-detail-back-to-product-link">
            <a @click="onBackToProductListClick">
                {{ getTranslation(isFromPartDetailsPage ? "Back" : "Back To Product List") }}
            </a>
            <driv-gpi-update-case-btn
                v-if="isGurusProductInsights"
                @onClick="onUpdateCase"
                :isReadOnly="isReadOnlyVersionGpi"
            ></driv-gpi-update-case-btn>
        </div>
        <driv-empty-part-results-page v-if="error"></driv-empty-part-results-page>
        <div v-if="!error" class="driv-part-detail-page-metadata">
            <driv-part-detail-page-carousel
                :carouselImages="carouselImages"
                :carouselThumbnails="carouselThumbnails"
                :carousel360Images="carousel360Images"
            ></driv-part-detail-page-carousel>
            <div :key="partDetail.part_number" class="part-detail-metadata">
                <driv-part-detail-page-header
                    :partDetail="partDetail"
                    :wtbPath="wtbPath"
                    :hideWtbBtns="hideWtbBtns"
                    :disableAddToCart="disableAddToCart"
                    :enableBeckCatalogButtons="enableBeckCatalogButtons"
                    :hideBrandLogo="hideBrandLogo"
                    :linkForCheckVehicleFitmentBtn="linkForCheckVehicleFitmentBtn"
                ></driv-part-detail-page-header>
                <driv-part-detail-page-content
                    :partDetail="partDetail"
                ></driv-part-detail-page-content>
            </div>
        </div>
        <part-notes-section
            v-if="isPartNotesSectionShown"
            :partNotes="partDetail.gpi_note_details.notes"
        ></part-notes-section>
        <div v-if="!error" class="driv-part-detail-page-tabs">
            <driv-tabs>
                <driv-tab v-if="isMobileView" :name="getTranslation('Description')" :order="0">
                    <driv-part-detail-page-content
                        :partDetail="partDetail"
                    ></driv-part-detail-page-content>
                </driv-tab>
                <driv-tab
                    v-if="partAttributes && partAttributes.length"
                    :name="getTranslation('Specifications')"
                    :selected="true"
                    :order="1"
                >
                    <driv-part-detail-page-specifications
                        :partAttributes="partAttributes"
                        :partType="partDetail.part_type"
                    ></driv-part-detail-page-specifications>
                </driv-tab>
                <driv-tab
                    v-if="Object.keys(applications).length"
                    :name="getTranslation('Applications')"
                    :order="2"
                >
                    <driv-part-detail-page-applications
                        :apiTabs="applications"
                        :key="Object.keys(applications).length"
                    ></driv-part-detail-page-applications>
                </driv-tab>
                <driv-tab
                    v-if="
                        partDetail.dam_assets.productDocuments ||
                        partDetail.dam_assets.productVideos
                    "
                    :name="getTranslation('Other media')"
                    :order="3"
                >
                    <driv-part-detail-other-media
                        :documents="documents"
                        :videos="partDetail.dam_assets.productVideos"
                    >
                    </driv-part-detail-other-media>
                </driv-tab>
                <driv-tab
                    v-if="partDetail.kits && partDetail.kits.length"
                    :name="getTranslation('Contents')"
                    :order="4"
                >
                    <driv-part-detail-page-contents
                        :kits="partDetail.kits"
                        :detailsPagePath="detailsPagePath"
                    ></driv-part-detail-page-contents>
                </driv-tab>
            </driv-tabs>
        </div>
    </div>
</template>

<script>
import {
    createUrlToGoToSearchResults,
    getTranslation,
} from "../../../common/partFinderCorporate.helpers";
import {
    getQsParams,
    mapPartToSalesforceObj,
} from "../../../driv-part-finder-part-results-page/clientlibs/src/helpers";
import { warningAttrValue } from "../../common/partDetailsPage.constants";
import EmptyPartResults from "../../../driv-part-finder-part-results-page/clientlibs/src/shared/EmptyPartResults.vue";

const DEFAULT_IMG_SRC = "/content/dam/placeholders/catalog-part-image.png";

export default Vue.component("driv-part-detail-page", {
    components: {
        EmptyPartResults,
    },
    props: {
        partResultsPageUrl: String,
        wtbPath: {
            type: String,
            default: "",
        },
        hideWtbBtns: {
            type: Boolean,
            default: false,
        },
        disableAddToCart: {
            type: Boolean,
            default: false,
        },
        enableBeckCatalogButtons: {
            type: Boolean,
            default: false,
        },
        hideBrandLogo: {
            type: Boolean,
            default: false,
        },
        linkForCheckVehicleFitmentBtn: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            partDetail: {
                brand: null,
                brand_name: null,
                dam_assets: {},
                descriptions: [],
                extended_informations: [],
                item_id: null,
                part_attributes: [],
                part_number: null,
                part_type: null,
                sub_brand: null,
                sub_brand_name: null,
                title: null,
                wtb_brand_name: null,
                gpi_note_details: { notes: [] },
            },
            mobileMaxBreakpoint: 767,
            isMobileView: null,
            carouselImages: [],
            carouselThumbnails: [],
            carousel360Images: [],
            applications: {},
            isLoading: true,
            documents: [],
            isFromPartDetailsPage: false,
            error: false,
        };
    },
    computed: {
        detailsPagePath: () => {
            return window.location.pathname.replace(".html", "");
        },
        partResultsUrl() {
            return createUrlToGoToSearchResults({
                path: this.partResultsPageUrl,
            });
        },
        partAttributes() {
            return this.partDetail.part_attributes?.filter(
                (attr) => attr.attribute !== warningAttrValue,
            );
        },
        isGurusProductInsights() {
            return window.brand === "gpi";
        },
        isReadOnlyVersionGpi() {
            const { signed_request } = getQsParams();

            return !signed_request;
        },
        isPartNotesSectionShown() {
            return (
                this.isGurusProductInsights &&
                this.partDetail?.gpi_note_details?.notes?.length &&
                !this.isReadOnlyVersionGpi &&
                !this.error
            );
        },
    },
    methods: {
        getTranslation,
        onBackToProductListClick() {
            const partResultsPageUrl = this.partResultsPageUrl;
            const partResultsUrl = this.partResultsUrl;

            Vue.Global.Analytics.trackEvent(
                "part-details",
                "click-back-to-search-results",
                partResultsUrl,
                null,
                {
                    callback: function () {
                        if (window.history.length) {
                            window.history.back();
                        } else {
                            if (partResultsPageUrl) {
                                window.location.assign(partResultsUrl);
                                return false;
                            }
                        }
                    },
                },
            );
        },
        checkMobileView() {
            this.isMobileView = window.innerWidth < this.mobileMaxBreakpoint;
        },
        getUniqueKey() {
            return `${this.partDetail.part_number}-${this.partDetail.brand}`;
        },
        onUpdateCase() {
            const category = this.partDetail.product_category || {};

            const partsData = [
                mapPartToSalesforceObj({
                    part: { ...this.partDetail, category },
                    shouldAddId: false,
                }),
            ];

            Vue.CatalogApi.CatalogApiService.sendPartsToSalesforce(partsData)
                .then((res) => {
                    window.Sfdc?.canvas?.client?.publish(res, {
                        name: "GPI.refresh",
                        payload: { status: "Completed" },
                    });
                })
                .catch((err) => console.error(err));
        },
    },
    created() {
        this.checkMobileView();
        window.addEventListener("resize", () => this.checkMobileView());

        const { part_number, brand_code, signed_request, isFromPartDetailsPage } = getQsParams();

        const decodedPartNumber = decodeURIComponent(part_number);
        const decodedBrandCode = decodeURIComponent(brand_code);

        if (isFromPartDetailsPage) {
            this.isFromPartDetailsPage = isFromPartDetailsPage;
        }

        Vue.CatalogApi.CatalogApiService.getPartDetail({
            part_number: decodedPartNumber,
            brand_code: decodedBrandCode,
            signed_request,
        })
            .then((response) => {
                this.partDetail = response;
                this.partDetail.id = this.getUniqueKey();

                if (response.dam_assets) {
                    this.carouselImages = response.dam_assets.productPrimaries.length
                        ? response.dam_assets.productPrimaries
                        : [{ url: DEFAULT_IMG_SRC }];
                    this.carouselThumbnails = response.dam_assets.productThumbnails;
                    this.carousel360Images = response.dam_assets.product360s || [];
                } else {
                    this.carouselImages = [{ url: DEFAULT_IMG_SRC }];
                }

                this.isLoading = false;

                return response;
            })
            .then((response) => {
                const productDocuments = response.dam_assets?.productDocuments;
                if (productDocuments) {
                    Promise.all(
                        productDocuments.map((doc) => {
                            if (doc) {
                                return Vue.CatalogApi.CatalogHttpService.getPdfUrl(doc.url).then(
                                    (pdfUrl) => {
                                        return {
                                            ...doc,
                                            file: pdfUrl,
                                        };
                                    },
                                );
                            }
                        }),
                    ).then((res) => {
                        const files = res?.filter(Boolean);
                        if (files?.length) {
                            this.documents = files;
                        }
                    });
                }
            })
            .catch(() => {
                this.error = true;
                this.isLoading = false;
            });

        Vue.CatalogApi.CatalogApiService.getPartDetailApplications(
            decodedPartNumber,
            decodedBrandCode,
        ).then((response) => {
            if (response.application_group_list) {
                this.applications = response.application_group_list;
            }
        });
    },
    destroyed() {
        window.removeEventListener("resize", () => this.checkMobileView());
    },
});
</script>
