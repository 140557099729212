var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "driv-part-detail-page-specifications-container" },
    [
      _c("div", { staticClass: "driv-part-detail-page-tab-data-header" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.partType) +
            " " +
            _vm._s(_vm.getTranslation("Dimensions")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _vm._l(_vm.partAttributes, function(attribute, i) {
        return _c(
          "div",
          { key: i, staticClass: "driv-part-detail-page-specification" },
          [
            _c(
              "div",
              { staticClass: "driv-part-detail-page-specification-label" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(attribute.attribute) +
                    "\n            "
                ),
                attribute.attribute_uom
                  ? _c("span", [
                      _vm._v("(" + _vm._s(attribute.attribute_uom) + ")")
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "driv-part-detail-page-specification-value" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(attribute.attribute_value) +
                    "\n        "
                )
              ]
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }