<template>
    <div v-if="isLoading" class="three-quarters-loader" />
    <div v-else class="driv-corp-diagram-results">
        <div class="diagram-results_header" :key="componentKey">
            <div class="back-to-product-link">
                <a @click="onBackToProductListClick">
                    {{ getTranslation("Back To Search Results") }}
                </a>
            </div>
            <div class="product-info">
                <div class="product-logo">
                    <img
                        v-if="imageExists"
                        :src="getBrandImgUrl(brandName)"
                        :alt="brandName"
                        @error="onErrorLoad"
                    />
                    <span class="brand" v-else>{{ brandName }}</span>
                </div>
                <fmmp-i18n text="Interactive diagrams"></fmmp-i18n>
            </div>
            <div class="all-diagrams-text">
                <fmmp-i18n text="All diagrams for the"></fmmp-i18n>
                <span>{{ modelInfo }}</span>
            </div>
        </div>
        <driv-empty-part-results-page v-if="isError"></driv-empty-part-results-page>
        <template v-else>
            <driv-diagram-results-carousel
                :toggleModal="handleHotspotClick"
                :carouselImages="svgDiagrams"
                :handleDiagramSelect="handleDiagramSelect"
            ></driv-diagram-results-carousel>

            <driv-diagram-info-modal
                @toggleDiagramInfoModal="handleToggleModal"
                :isModalVisible="isModalVisible"
                :data="selectedHotspotData"
                :title="selectedDiagramTitle"
                :detailsPath="detailsPath"
            ></driv-diagram-info-modal
        ></template>
    </div>
</template>

<script>
import InteractiveDiagramModal from "./InteractiveDiagramModal.vue";
import DiagramCarousel from "./DiagramCarousel.vue";
import {
    createUrlToGoToSearchResults,
    getTranslation,
    getBrandImgUrl,
} from "../../../../common/partFinderCorporate.helpers";
import { FILTERS_QS_OPTIONS } from "../constants";
import { getHashParams, getQsParams } from "../helpers";
import { addInteractivityForWalkerDiagram } from "./interactiveDiagrams.helpers";

const HOTSPOT_ID = "Hotspot_";
const MOOG = {
    LABEL: "moog",
    VALUE: "bcch",
};

export default Vue.component("driv-corp-diagram-results", {
    components: {
        InteractiveDiagramModal,
        DiagramCarousel,
    },
    props: {
        detailsPath: {
            type: String,
            default: "",
        },
        diagrams: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            componentKey: 0,
            imageExists: true,
            isModalVisible: false,
            selectedHotspotData: [],
            selectedDiagramTitle: "",
            svgDiagrams: [],
            isLoading: true,
            hotspotsMap: {},
            isError: false,
        };
    },
    computed: {
        qsParams() {
            return getQsParams();
        },
        brandName() {
            return this.qsParams.brandInfo?.label || "";
        },
        modelInfo() {
            const { year, make, model } = this.qsParams;
            return `${year.label} ${make.label} ${model.label}`;
        },
        isMoogBrand() {
            return (
                this.brandName.toLowerCase() === MOOG.LABEL ||
                (this.qsParams.brandInfo?.value || "").toLowerCase() === MOOG.VALUE
            );
        },
    },
    methods: {
        getTranslation,
        getBrandImgUrl,
        onBackToProductListClick() {
            const pagePath = window.location.pathname.replace(".html", "");
            const { isInteractiveDiagram, brandName, ...queryObj } = this.qsParams;
            const hashString = getHashParams(FILTERS_QS_OPTIONS);
            const url = createUrlToGoToSearchResults({
                queryObj,
                path: pagePath,
                hashString,
            });

            Vue.Global.Analytics.trackEvent(
                "interactive-diagrams-results",
                "click-back-to-search-results",
                url,
                null,
                {
                    callback: function () {
                        return window.location.assign(url);
                    },
                },
            );
        },
        handleToggleModal() {
            this.isModalVisible = !this.isModalVisible;
        },
        handleHotspotClick(e, fileName) {
            if (!this.isMoogBrand) return;

            const target = e.target;
            const id = target?.id || target?.parentNode?.id || target?.parentNode?.parentNode?.id;
            const isHotspotClick = id && id.indexOf(HOTSPOT_ID) !== -1;

            if (isHotspotClick) {
                this.selectedHotspotData = this.hotspotsMap[fileName][id];

                if (this.selectedHotspotData?.length) {
                    const handleToggleModal = this.handleToggleModal;
                    const eventParams = this.selectedHotspotData
                        .map((item) => item.part_number)
                        .join("|");
                    const selectedDiagram = this.svgDiagrams.find(
                        (diagram) => diagram.file_name === fileName,
                    );

                    this.selectedDiagramTitle = selectedDiagram.title;

                    Vue.Global.Analytics.trackEvent(
                        "interactive-diagrams-results",
                        "click-interactive-diagram-hotspot",
                        eventParams,
                        null,
                        {
                            callback: function () {
                                handleToggleModal();
                            },
                        },
                    );
                }
            }
        },
        onErrorLoad() {
            this.imageExists = false;
            // force re-render
            this.componentKey += 1;
        },
        handleDiagramSelect(fileName) {
            const selectedDiagram = document.getElementById(fileName);

            if (!this.isMoogBrand) {
                addInteractivityForWalkerDiagram(selectedDiagram);
                return;
            }

            if (!!this.hotspotsMap[fileName]) return;

            const hotspots = selectedDiagram.querySelectorAll(`[id^=${HOTSPOT_ID}]`);
            const { year, make, model, brandInfo } = this.qsParams;
            const params = {
                fileName,
                yearId: year?.value,
                makeId: make?.value,
                modelId: model?.value,
                brandCodes: brandInfo?.value,
            };

            Promise.all(
                Array.prototype.slice.call(hotspots).map((hotspot, i) => {
                    const hotspotId = hotspot?.id.split("_")[1];
                    return Vue.CatalogApi.CatalogApiService.getPartsListForHotspot({
                        ...params,
                        hotspotId,
                    }).then((res) => {
                        const partsList = res.application_list?.applications;

                        if (partsList?.length) {
                            hotspots[i].setAttribute("class", "interactive");
                        }
                        return { [hotspot?.id]: partsList };
                    });
                }),
            ).then((response) => {
                this.hotspotsMap[fileName] = response.reduce(
                    (acc, item) => ({ ...acc, ...item }),
                    {},
                );

                this.svgDiagrams.forEach((diagram) => {
                    if (diagram.file_name === fileName) {
                        this.selectedDiagramTitle = diagram.title;
                        diagram.svg = selectedDiagram.innerHTML;
                    }
                });
            });
        },
    },
    created() {
        Promise.all(
            this.diagrams.map((diagram) => {
                if (diagram) {
                    return Vue.CatalogApi.CatalogHttpService.get(diagram.url).then((res) => {
                        const isDesc = res.indexOf('<desc>') > -1;
                        let desc = "";
                        if (isDesc) {
                        const startOfDesc = res.indexOf('<desc>') + 6;
                        const endOfDesc = res.indexOf('</desc>', startOfDesc);
                            desc = res.slice(startOfDesc, endOfDesc) || "";
                        desc = desc.replace(/_/g, " ");
                        }
                        return {
                            ...diagram,
                            desc: desc? desc : "",
                            svg: res,
                        };
                    });
                }
            }),
        )
            .then((res) => {
                this.isLoading = false;
                const files = res?.filter(Boolean);
                if (files?.length) {
                    this.isError = false;
                    this.svgDiagrams = files;
                } else {
                    this.isError = true;
                }
            })
            .catch(() => {
                this.isLoading = false;
                this.isError = true;
            });
    },
});
</script>
