<template>
    <div class="applications-subtab small-engine-tab">
        <div v-for="(product, i) in tabsData" :key="i" class="product">
            <div class="product__manufacturer">
                {{ transformToMissedValue(product.mfr && product.mfr.value) }}
            </div>
            <div class="product__model">
                {{
                    transformToMissedValue(product.equipment_model && product.equipment_model.value)
                }}
            </div>
        </div>
    </div>
</template>

<script>
import { transformToMissedValue } from "../../../../common/partFinderCorporate.helpers";

export default Vue.component("driv-part-detail-applications-small-engine", {
    props: {
        tabsData: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        transformToMissedValue,
    },
});
</script>
