<template>
    <div class="driv-part-detail-page-contents-container">
        <div class="driv-part-detail-page-tab-data-header">
            {{ getTranslation("Components") }}
        </div>
        <table class="tab-table desktop">
            <thead>
                <tr>
                    <th v-for="column in columns" :key="column.id">
                        {{ getTranslation(column.name) }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(kit, i) in kits" :key="i" class="content">
                    <td v-for="column in columns" :key="column.id">
                        <a
                            v-if="column.id === columns[0].id"
                            class="part-number"
                            rel="noopener noreferrer"
                            target="_blank"
                            @click="
                                getPartDetail(
                                    kit.kit_part_number,
                                    kit.kit_brand,
                                    detailsPagePath,
                                    showQuickDetails ? {} : { isFromPartDetailsPage: true },
                                )
                            "
                            >{{ kit[column.id] }}</a
                        >
                        <span v-else>{{ kit[column.id] }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="tab-table mobile">
            <div v-for="(kit, i) in kits" :key="i" class="content">
                <div v-for="column in columns" :key="column.id">
                    <span>{{ getTranslation(column.name) }}:</span>
                    {{ kit[column.id] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CONTENTS_TAB_COLUMNS_LIST } from "../../../common/partDetailsPage.constants";
import {
    createUrlToGoToSearchResults,
    getTranslation,
} from "../../../../common/partFinderCorporate.helpers";
import { SAVED_QUICK_DETAILS_PART_KEY } from "../../../../driv-part-finder-part-results-page/clientlibs/src/constants";

export default Vue.component("driv-part-detail-page-contents", {
    data() {
        return {
            columns: CONTENTS_TAB_COLUMNS_LIST,
        };
    },
    props: {
        kits: Array,
        detailsPagePath: {
            type: String,
            default: "",
        },
        showQuickDetails: {
            type: String,
            default: "",
        },
    },
    methods: {
        getTranslation,
        getPartDetail(partNumber, brandCode, path, isFromPartDetailsPage) {
            if (!path) return;
            if (this.showQuickDetails) {
                sessionStorage.setItem(SAVED_QUICK_DETAILS_PART_KEY, this.showQuickDetails);
            }
            Vue.Global.Analytics.trackEvent("Part Kits Table Link", "Part", partNumber, null, {
                callback: function () {
                    window.location.assign(
                        createUrlToGoToSearchResults({
                            queryObj: {
                                part_number: partNumber,
                                brand_code: brandCode,
                                isFromPartDetailsPage,
                            },
                            path,
                        }),
                    );
                },
            });
        },
    },
});
</script>
