var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("div", { staticClass: "three-quarters-loader" })
    : _c(
        "div",
        { staticClass: "driv-part-detail-page" },
        [
          _c(
            "div",
            { staticClass: "driv-part-detail-back-to-product-link" },
            [
              _c("a", { on: { click: _vm.onBackToProductListClick } }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.getTranslation(
                        _vm.isFromPartDetailsPage
                          ? "Back"
                          : "Back To Product List"
                      )
                    ) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _vm.isGurusProductInsights
                ? _c("driv-gpi-update-case-btn", {
                    attrs: { isReadOnly: _vm.isReadOnlyVersionGpi },
                    on: { onClick: _vm.onUpdateCase }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.error ? _c("driv-empty-part-results-page") : _vm._e(),
          _vm._v(" "),
          !_vm.error
            ? _c(
                "div",
                { staticClass: "driv-part-detail-page-metadata" },
                [
                  _c("driv-part-detail-page-carousel", {
                    attrs: {
                      carouselImages: _vm.carouselImages,
                      carouselThumbnails: _vm.carouselThumbnails,
                      carousel360Images: _vm.carousel360Images
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      key: _vm.partDetail.part_number,
                      staticClass: "part-detail-metadata"
                    },
                    [
                      _c("driv-part-detail-page-header", {
                        attrs: {
                          partDetail: _vm.partDetail,
                          wtbPath: _vm.wtbPath,
                          hideWtbBtns: _vm.hideWtbBtns,
                          disableAddToCart: _vm.disableAddToCart,
                          enableBeckCatalogButtons:
                            _vm.enableBeckCatalogButtons,
                          hideBrandLogo: _vm.hideBrandLogo,
                          linkForCheckVehicleFitmentBtn:
                            _vm.linkForCheckVehicleFitmentBtn
                        }
                      }),
                      _vm._v(" "),
                      _c("driv-part-detail-page-content", {
                        attrs: { partDetail: _vm.partDetail }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isPartNotesSectionShown
            ? _c("part-notes-section", {
                attrs: { partNotes: _vm.partDetail.gpi_note_details.notes }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.error
            ? _c(
                "div",
                { staticClass: "driv-part-detail-page-tabs" },
                [
                  _c(
                    "driv-tabs",
                    [
                      _vm.isMobileView
                        ? _c(
                            "driv-tab",
                            {
                              attrs: {
                                name: _vm.getTranslation("Description"),
                                order: 0
                              }
                            },
                            [
                              _c("driv-part-detail-page-content", {
                                attrs: { partDetail: _vm.partDetail }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.partAttributes && _vm.partAttributes.length
                        ? _c(
                            "driv-tab",
                            {
                              attrs: {
                                name: _vm.getTranslation("Specifications"),
                                selected: true,
                                order: 1
                              }
                            },
                            [
                              _c("driv-part-detail-page-specifications", {
                                attrs: {
                                  partAttributes: _vm.partAttributes,
                                  partType: _vm.partDetail.part_type
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      Object.keys(_vm.applications).length
                        ? _c(
                            "driv-tab",
                            {
                              attrs: {
                                name: _vm.getTranslation("Applications"),
                                order: 2
                              }
                            },
                            [
                              _c("driv-part-detail-page-applications", {
                                key: Object.keys(_vm.applications).length,
                                attrs: { apiTabs: _vm.applications }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.partDetail.dam_assets.productDocuments ||
                      _vm.partDetail.dam_assets.productVideos
                        ? _c(
                            "driv-tab",
                            {
                              attrs: {
                                name: _vm.getTranslation("Other media"),
                                order: 3
                              }
                            },
                            [
                              _c("driv-part-detail-other-media", {
                                attrs: {
                                  documents: _vm.documents,
                                  videos:
                                    _vm.partDetail.dam_assets.productVideos
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.partDetail.kits && _vm.partDetail.kits.length
                        ? _c(
                            "driv-tab",
                            {
                              attrs: {
                                name: _vm.getTranslation("Contents"),
                                order: 4
                              }
                            },
                            [
                              _c("driv-part-detail-page-contents", {
                                attrs: {
                                  kits: _vm.partDetail.kits,
                                  detailsPagePath: _vm.detailsPagePath
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }