<template>
    <div class="applications-subtab powersport-tab">
        <div v-for="(product, i) in tabsData" :key="i" class="product">
            <div class="car__make">
                {{ transformToMissedValue(product.make && product.make.value) }}
            </div>
            <div class="car__model">
                {{ transformToMissedValue(product.model && product.model.value) }}
            </div>
            <div class="car__years">
                {{ transformToMissedValue(product.years && displayYearsRange(product.years)) }}
            </div>
        </div>
    </div>
</template>

<script>
import { transformToMissedValue } from "../../../../common/partFinderCorporate.helpers";
import { displayYearsRange } from "../../../common/partDetailsPage.helpers";

export default Vue.component("driv-part-detail-applications-powersport", {
    props: {
        tabsData: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        transformToMissedValue,
        displayYearsRange,
    },
});
</script>
