<template>
    <div class="driv-part-detail-page-header">
        <div class="part-detail-header-data">
            <div class="part-detail-header-metadata">
                <driv-part-detail-page-logo
                    v-if="!hideBrandLogo"
                    :partDetail="partDetail"
                ></driv-part-detail-page-logo>
                <div class="part-detail-header-info">
                    <p class="part-detail-header-part-name">{{ partDetail.part_type }}</p>
                    <p class="part-detail-header-part-number">
                        <span> {{ getTranslation(partNumberTitle) }} </span>
                        {{ partDetail.part_number }}
                    </p>
                </div>
                <div v-if="enableBeckCatalogButtons" class="add-to-cart-beckcatalog">
                    <a
                        :href="addToCartUrl"
                        rel="noreferrer noopener"
                        target="_blank"
                        class="add-to-cart-beckcatalog-button"
                    >
                        {{ getTranslation("Add to cart") }}
                    </a>
                </div>
            </div>
            <div class="check-vehicle-fitment" v-if="linkForCheckVehicleFitmentBtn">
                <a
                    class="check-vehicle-fitment-button"
                    :href="computedLinkForVehicleFitmentBtn"
                    rel="noreferrer noopener"
                >
                    {{ getTranslation("Check vehicle fitment") }}
                </a>
            </div>
            <div class="part-detail-header-ctas">
                <driv-dropdown-button
                    v-if="
                        !hideWtbBtns &&
                        partDetail.online_store_list &&
                        partDetail.online_store_list.length
                    "
                    buttonClasses="driv-corp-list-view-item-wtb-button part-detail-header-button buy-now-btn"
                    :text="getTranslation('Buy It Now')"
                    :options="
                        mapPartOnlineStoreToOptions(
                            partDetail.online_store_list || [],
                            partDetail.part_number,
                        )
                    "
                ></driv-dropdown-button>
                <a
                    v-if="!hideWtbBtns"
                    class="part-detail-header-button"
                    @click="
                        () =>
                            handleWtbClick({
                                partNumber: partDetail.part_number,
                                brand: partDetail.wtb_brand_name,
                                locType: 'install',
                            })
                    "
                >
                    {{ getTranslation("Get it installed") }}
                </a>
                <a
                    v-if="!hideWtbBtns"
                    class="part-detail-header-button"
                    @click="
                        () =>
                            handleWtbClick({
                                partNumber: partDetail.part_number,
                                brand: partDetail.wtb_brand_name,
                                locType: 'store',
                            })
                    "
                >
                    {{ getTranslation("Where to Buy") }}
                </a>
                <a
                    v-if="isCorporateSite"
                    class="part-detail-header-button parts-list"
                    @click="handleButtonClick"
                >
                    {{ getTranslation(partsListBtnName) }}
                </a>
            </div>
        </div>
        <driv-part-list-modal
            :partListModal="partListModal"
            @updatePartListModal="updatePartListModal"
            :disableAddToCart="disableAddToCart"
        />
    </div>
</template>

<script>
import { WTB_EVENTS } from "../../../common/partFinderCorporate.constants";
import { getCorporateWtbUrl } from "../../../driv-part-finder-part-results-page/clientlibs/src/helpers";
import {
    mapPartOnlineStoreToOptions,
    getTranslation,
    getCorrectUrlForLinks,
} from "../../../common/partFinderCorporate.helpers";
import { LOCAL_STORAGE_PROPERTIES } from "./constants";

const viewBtnText = "view my parts list";
const addBtnText = "add to parts list";
const ADD_TO_BECKCATALOG_CART_URL = "https://www.beckorder.com/BAWP/addToCart.jsp";

export default Vue.component("driv-part-detail-page-header", {
    props: {
        partDetail: {
            type: Object,
            default: () => ({}),
        },
        wtbPath: {
            type: String,
            default: "",
        },
        hideWtbBtns: {
            type: Boolean,
            default: false,
        },
        disableAddToCart: {
            type: Boolean,
            default: false,
        },
        enableBeckCatalogButtons: {
            type: Boolean,
            default: false,
        },
        hideBrandLogo: {
            type: Boolean,
            default: false,
        },
        linkForCheckVehicleFitmentBtn: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            partsListBtnName: addBtnText,
            partListModal: false,
        };
    },
    methods: {
        getTranslation,
        mapPartOnlineStoreToOptions,
        handleWtbClick({ brand, partNumber, locType }) {
            if (this.wtbPath) {
                const url = getCorporateWtbUrl({
                    brand,
                    locType,
                    wtbPath: this.wtbPath,
                });

                Vue.Global.Analytics.trackEvent(
                    "where-to-buy",
                    WTB_EVENTS[locType],
                    partNumber,
                    null,
                    {
                        callback: function () {
                            window.location.assign(url);
                        },
                    },
                );
            }
        },
        checkIfPartAdded() {
            const partsList = localStorage[this.partsListKey];

            return (
                partsList && !!JSON.parse(partsList).find((part) => part.id === this.partDetail.id)
            );
        },
        initPartsListButtonName() {
            const isAdded = this.checkIfPartAdded();
            this.partsListBtnName = isAdded ? viewBtnText : addBtnText;
        },
        filterPartDetailObj() {
            let obj = this.partDetail;
            return {
                id: obj.id,
                brand_name: obj.brand_name,
                part_number: obj.part_number,
                part_type: obj.part_type,
                brand_code: obj.hybris_brand_code,
                imageUrl: (obj.dam_assets?.productPrimaries || [])[0]?.url || "",
                quantity: 1,
            };
        },
        addToPartsList() {
            const isAdded = this.checkIfPartAdded();

            if (!isAdded) {
                const filteredPartDetail = this.filterPartDetailObj();
                const partsListKey = this.partsListKey;
                const parsedPartsList = JSON.parse(localStorage[partsListKey] || "[]");

                Vue.Global.Analytics.trackEvent(
                    "parts-list",
                    "click-add-to-parts-list",
                    this.partDetail.part_number,
                    null,
                    {
                        callback: function () {
                            localStorage[partsListKey] = JSON.stringify([
                                ...parsedPartsList,
                                filteredPartDetail,
                            ]);
                        },
                    },
                );
            }

            this.partsListBtnName = viewBtnText;
        },
        openPartsListModal() {
            this.partListModal = !this.partListModal;
        },
        handleButtonClick() {
            this.partsListBtnName === addBtnText
                ? this.addToPartsList()
                : this.openPartsListModal();
        },
        updatePartListModal(partListModalUpdated) {
            this.partListModal = partListModalUpdated;
            this.initPartsListButtonName();
        },
    },
    mounted() {
        this.initPartsListButtonName();
    },
    computed: {
        partsListKey() {
            return this.disableAddToCart
                ? LOCAL_STORAGE_PROPERTIES.LOCAL_PARTS_LIST
                : LOCAL_STORAGE_PROPERTIES.ADD_TO_CART_PARTS_LIST;
        },
        computedLinkForVehicleFitmentBtn() {
            if (!this.linkForCheckVehicleFitmentBtn) return;

            return getCorrectUrlForLinks(this.linkForCheckVehicleFitmentBtn);
        },
        addToCartUrl() {
            return `${ADD_TO_BECKCATALOG_CART_URL}?parts={list:{kit:"",kitseq:"",kitQty:"",items:[{qty:"1",part:${this.partDetail.part_number}}]}}`;
        },
        isCorporateSite() {
            return window.brand === "corporate";
        },
        isGurusProductInsights() {
            return window.brand === "gpi";
        },
        partNumberTitle() {
            return this.isGurusProductInsights ? "Part #" : "Part Number:";
        },
    },
});
</script>
