var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "driv-part-detail-page-contents-container" },
    [
      _c("div", { staticClass: "driv-part-detail-page-tab-data-header" }, [
        _vm._v(
          "\n        " + _vm._s(_vm.getTranslation("Components")) + "\n    "
        )
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "tab-table desktop" }, [
        _c("thead", [
          _c(
            "tr",
            _vm._l(_vm.columns, function(column) {
              return _c("th", { key: column.id }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.getTranslation(column.name)) +
                    "\n                "
                )
              ])
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.kits, function(kit, i) {
            return _c(
              "tr",
              { key: i, staticClass: "content" },
              _vm._l(_vm.columns, function(column) {
                return _c("td", { key: column.id }, [
                  column.id === _vm.columns[0].id
                    ? _c(
                        "a",
                        {
                          staticClass: "part-number",
                          attrs: {
                            rel: "noopener noreferrer",
                            target: "_blank"
                          },
                          on: {
                            click: function($event) {
                              return _vm.getPartDetail(
                                kit.kit_part_number,
                                kit.kit_brand,
                                _vm.detailsPagePath,
                                _vm.showQuickDetails
                                  ? {}
                                  : { isFromPartDetailsPage: true }
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(kit[column.id]))]
                      )
                    : _c("span", [_vm._v(_vm._s(kit[column.id]))])
                ])
              }),
              0
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab-table mobile" },
        _vm._l(_vm.kits, function(kit, i) {
          return _c(
            "div",
            { key: i, staticClass: "content" },
            _vm._l(_vm.columns, function(column) {
              return _c("div", { key: column.id }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.getTranslation(column.name)) + ":")
                ]),
                _vm._v(
                  "\n                " +
                    _vm._s(kit[column.id]) +
                    "\n            "
                )
              ])
            }),
            0
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }