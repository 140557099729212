export const PART_DETAIL_APPLICATIONS = {
    VEHICLE: {
        id: "PASSENGER CAR & LIGHT TRUCK",
        name: "Passenger Car & Light Truck",
    },
    COMMERCIAL_UNDUSTRIAL_AG: {
        id: "COMMERCIAL, INDUSTRIAL & AG.",
        name: "Commercial, Industrial & AG.",
    },
    POWERSPORT: {
        id: "POWERSPORT",
        name: "Powersport",
    },
    SMALL_ENGINE: {
        id: "SMALL ENGINE",
        name: "Small Engine",
    },
    LAWN_AND_GARDEN: {
        id: "LAWN AND GARDEN",
        name: "Lawn And Garden",
    },
    MARINE: {
        id: "MARINE APPLICATIONS",
        name: "Marine Applications",
    },
    PERFORMANCE: {
        id: "PERFORMANCE",
        name: "Performance",
    },
    HEAVY_DUTY: {
        id: "HEAVY DUTY ENGINE",
        name: "Heavy Duty Engine",
    },
};

// value - api property
export const PART_DETAIL_APPLICATIONS_SUBTABS = [
    {
        label: PART_DETAIL_APPLICATIONS.VEHICLE.name,
        value: PART_DETAIL_APPLICATIONS.VEHICLE.id,
    },
    {
        label: PART_DETAIL_APPLICATIONS.COMMERCIAL_UNDUSTRIAL_AG.name,
        value: PART_DETAIL_APPLICATIONS.COMMERCIAL_UNDUSTRIAL_AG.id,
    },
    {
        label: PART_DETAIL_APPLICATIONS.POWERSPORT.name,
        value: PART_DETAIL_APPLICATIONS.POWERSPORT.id,
    },
    {
        label: PART_DETAIL_APPLICATIONS.SMALL_ENGINE.name,
        value: PART_DETAIL_APPLICATIONS.SMALL_ENGINE.id,
    },
    {
        label: PART_DETAIL_APPLICATIONS.LAWN_AND_GARDEN.name,
        value: PART_DETAIL_APPLICATIONS.LAWN_AND_GARDEN.id,
    },
    {
        label: PART_DETAIL_APPLICATIONS.MARINE.name,
        value: PART_DETAIL_APPLICATIONS.MARINE.id,
    },
    {
        label: PART_DETAIL_APPLICATIONS.PERFORMANCE.name,
        value: PART_DETAIL_APPLICATIONS.PERFORMANCE.id,
    },
    {
        label: PART_DETAIL_APPLICATIONS.HEAVY_DUTY.name,
        value: PART_DETAIL_APPLICATIONS.HEAVY_DUTY.id,
    },
];

export const warningAttrValue = "California Proposition 65";

export const warningAttrNoValue = "No";

export const PROPS = {
    MAKE: "make",
    MFR: "mrf",
    MODEL: "model",
    EQUIPMENT_MODEL: "equipment_model",
    YEARS: "years",
    PRODUCTION_YEARS: "production_years",
    CATEGORY: "category",
    POSITION: "position",
    DRIVE_WHEEL: "drive",
    QUANTITY: "qty",
    ENGINE_MFR: "engine_mfr",
    ENGINE_BASE: "engine_base",
    ENGINE_BASE_VALUE: "engine_base_value",
    ENGINE_VIN: "engine_vin",
    ENGINE_VERSION: "engine_version_value",
    ENGINE_DESIGNATION: "engine_designation",
};

export const PASSENGER_CAR_TAB_COLUMNS = [
    { column: "Make", property: PROPS.MAKE, class: "make" },
    { column: "Model", property: PROPS.MODEL, class: "model" },
    { column: "Year Range", property: PROPS.YEARS, class: "years" },
    { column: "Description", property: PROPS.CATEGORY, class: "description" },
    { column: "Position", property: PROPS.POSITION, class: "position" },
    { column: "Drive Wheel", property: PROPS.DRIVE_WHEEL, class: "drive-wheel" },
    { column: "Veh. Qty.", property: PROPS.QUANTITY, class: "vehicle-quantity" },
    { column: "Engine Base", property: PROPS.ENGINE_BASE, class: "engine-base" },
    { column: "Engine VIN", property: PROPS.ENGINE_VIN, class: "engine-vin" },
    { column: "Engine Desg.", property: PROPS.ENGINE_DESIGNATION, class: "engine-desg" },
];

// The ids correspond to kits' property names
export const CONTENTS_TAB_COLUMNS = {
    PART_NUMBER: { name: "Part Number", id: "kit_part_number" },
    PART_TYPE: { name: "Part Type", id: "description" },
    QUANTITY: { name: "Quantity", id: "quantity_in_kit" },
};

export const CONTENTS_TAB_COLUMNS_LIST = Object.values(CONTENTS_TAB_COLUMNS);
