import { APPLICATION_TYPE_KEYS } from "../partFinderCorporate.constants";
import { FIELDS } from "./constants";

export const getApplicationTypesOptionsFromResponse = (valuePropName, labelPropName) => (item) => ({
    value: `${item[valuePropName]}`
        .replace(/\s|[ ,]+$/g, "")
        .split(",")
        .sort((a, b) => a - b)
        .join(","),
    label: item[labelPropName],
});

export const getOptionsFromResponse = (valuePropName, labelPropName) => (item) => ({
    value: item[valuePropName],
    label: item[labelPropName],
});

export const resetField = (value) => {
    value.items = [];
    value.model = "";
    value.disabled = true;
};

export const getModelFromFieldObject = (field) => {
    return field?.model || {};
};

export const getVehicleTypesOptionsFromString = (vehicleTypesString) => {
    const vehicleTypes = JSON.parse(vehicleTypesString);

    return vehicleTypes.map(
        getOptionsFromResponse(APPLICATION_TYPE_KEYS.value, APPLICATION_TYPE_KEYS.label),
    );
};

const { ENGINE_APPLICATION_TYPES } = Vue.PartFinder;
const LIGHT_MEDIUM_DUTY_IDS = [
    ENGINE_APPLICATION_TYPES.LIGHT_MEDIUM_DUTY.id,
    ENGINE_APPLICATION_TYPES.MEDIUM_DUTY.id,
];

export const getEngineType = (vehicleGroupIds) => {
    const ids = vehicleGroupIds.split(",");
    return LIGHT_MEDIUM_DUTY_IDS.some((id) => ids.includes(`${id}`)) ? "ld" : "hd";
};

export const getResultField = (result, fieldKey) => {
    if (fieldKey === FIELDS.HEAVY_DUTY_MFRS) {
        return result[FIELDS.MANUFACTURER];
    } else if (fieldKey === FIELDS.HEAVY_DUTY_BASE || fieldKey === FIELDS.PERFORMANCE_BASE) {
        return result[FIELDS.BASE];
    }
    return result[fieldKey];
};