var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "driv-part-detail-page-content" }, [
    _c(
      "div",
      { staticClass: "description-text" },
      [_c("fmmp-i18n", { attrs: { text: "Description" } })],
      1
    ),
    _vm._v(" "),
    _vm.warningText
      ? _c("div", { staticClass: "part-detail-warning" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("p", { staticClass: "part-detail-warning_text" }, [
            _vm._v(_vm._s(_vm.warningText))
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "part-detail-content-characteristic" }, [
      _c("p", { staticClass: "part-detail-content-characteristic_title" }, [
        _vm._v("\n            " + _vm._s(_vm.fab.title) + "\n        ")
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "part-detail-content-characteristic_list" },
        _vm._l(_vm.fab.content, function(option) {
          return _c("li", { key: option.content }, [
            _vm._v(
              "\n                " + _vm._s(option.content) + "\n            "
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("i", { staticClass: "fal fa-exclamation-triangle" }, [
      _c("span", { staticClass: "triangle-lining" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }