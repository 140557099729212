<template>
    <div class="part-notes-section">
        <div class="part-notes-header">
            <fmmp-i18n text="Part notes" class="part-notes-header__title" />
        </div>
        <div class="part-notes">
            <div class="part-note" v-for="(partNote, i) in partNotes" :key="i">
                <div class="column note-column">
                    <div class="column__title">
                        <fmmp-i18n text="Note" />
                    </div>
                    <div class="column__value">
                        <span v-html="partNote.note"></span>
                    </div>
                </div>
                <div class="column details-column">
                    <div class="column__title">
                        <fmmp-i18n text="Note details" />
                    </div>
                    <div class="column__value">
                        <div class="metadata">
                            <fmmp-i18n text="Created by: " />
                            {{ partNote.createdBy }}
                        </div>
                        <div class="metadata">
                            <fmmp-i18n text="Created date: " />
                            {{ partNote.createdDate }}
                        </div>
                        <div class="metadata">
                            <fmmp-i18n text="Updated by: " />
                            {{ partNote.lastModifiedBy }}
                        </div>
                        <div class="metadata">
                            <fmmp-i18n text="Update date: " />
                            {{ partNote.lastModifiedDate }}
                        </div>
                    </div>
                </div>
                <div
                    :class="[
                        'column additional-info-column',
                        { 'hidden-mobile': !partNote.files.length },
                    ]"
                >
                    <div class="column__title">
                        <fmmp-i18n text="Additional info" />
                    </div>
                    <div class="column__value">
                        <div class="files">
                            <div
                                class="file"
                                v-for="file in partNote.files"
                                :key="file.content_version_id"
                            >
                                <i class="fa-solid fa-file-pdf" v-if="isPdfDocument(file)"></i>
                                <i class="fa-solid fa-file" v-else></i>
                                <a
                                    class="document-url"
                                    :id="file.content_version_id"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    :type="getBlobTypeForFile(file)"
                                    @click="onPartNoteFileClick(file)"
                                >
                                    {{ file.name }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default Vue.component("part-notes-section", {
    props: {
        partNotes: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        isPdfDocument(file) {
            return file.extension === "pdf";
        },
        getBlobTypeForFile(file) {
            if (this.isPdfDocument(file)) {
                return "application/pdf";
            }
            return `image/${file.extension}`;
        },
        onPartNoteFileClick(file) {
            const fileLink = document.getElementById(file.content_version_id);

            if (fileLink.href) return;

            Vue.CatalogApi.CatalogApiService.getPartNoteFile(file.content_version_id)
                .then((blob) => {
                    const blobStore = new Blob([blob], {
                        type: this.getBlobTypeForFile(file),
                    });
                    const url = window.URL.createObjectURL(blobStore);

                    fileLink.href = url;

                    window.open(url, "_blank");
                })
                .catch((error) => console.error(error));
        },
    },
});
</script>
