<template>
    <div class="applications-subtab tab-table">
        <table class="tab-table desktop">
            <thead>
                <tr>
                    <th v-for="column in columns" :key="column.column">
                        {{ getTranslation(column.column) }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(product, i) in tabsData" :key="i" class="product">
                    <td
                        v-for="column in columns"
                        :class="`product__${column.class}`"
                        :key="column.column"
                    >
                        {{
                            transformToBlankSpace(
                                checkIfPropExistInPassengerCarTab(column, product),
                            )
                        }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="tab-table mobile">
            <div v-for="(product, i) in tabsData" :key="i" class="product">
                <div
                    v-for="column in mobileColumns"
                    :class="`product__${column.class}`"
                    :key="column.column"
                >
                    <span>{{ getTranslation(column.column) }}:</span>
                    {{ transformToMissedValue(checkIfPropExistInPassengerCarTab(column, product)) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PASSENGER_CAR_TAB_COLUMNS } from "../../../common/partDetailsPage.constants";
import {
    checkIfPropExistInPassengerCarTab,
    filterColumnsForMobileView,
} from "../../../common/partDetailsPage.helpers";
import {
    getTranslation,
    transformToMissedValue,
} from "../../../../common/partFinderCorporate.helpers";

export default Vue.component("driv-part-detail-applications-passenger-car", {
    data() {
        return {
            columns: PASSENGER_CAR_TAB_COLUMNS,
        };
    },
    props: {
        tabsData: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        getTranslation,
        transformToMissedValue,
        checkIfPropExistInPassengerCarTab,
        filterColumnsForMobileView,
        transformToBlankSpace(field) {
            const returnValue = this.isGurusProductInsights ? "-" : "";
            return transformToMissedValue(field, returnValue);
        },
    },
    computed: {
        mobileColumns() {
            return filterColumnsForMobileView(this.columns);
        },
        isGurusProductInsights() {
            return window.brand === "gpi";
        },
    },
});
</script>
