var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "driv-part-detail-page-other-media" }, [
    _vm.documents.length
      ? _c("div", [
          _c("div", { staticClass: "driv-part-detail-page-tab-data-header" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.getTranslation("Documents")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "media-container" },
            _vm._l(_vm.documents, function(document) {
              return _c(
                "a",
                {
                  key: document.index,
                  staticClass: "document",
                  attrs: {
                    href: document.file,
                    target: "_blank",
                    rel: "noreferrer noopener",
                    type: "application/pdf"
                  }
                },
                [
                  _c("div", { staticClass: "document-title" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(document.title) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  document.language
                    ? _c("div", { staticClass: "document-language" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.getTranslation("language")) +
                            ": " +
                            _vm._s(document.language) +
                            "\n                "
                        )
                      ])
                    : _vm._e()
                ]
              )
            }),
            0
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.videosWithIframeUrl.length
      ? _c("div", [
          _c("div", { staticClass: "driv-part-detail-page-tab-data-header" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.getTranslation("Videos")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "media-container" },
            _vm._l(_vm.videosWithIframeUrl, function(video) {
              return _c("iframe", {
                key: video.index,
                staticClass: "video",
                attrs: { src: video.iframeUrl, allowfullscreen: "" }
              })
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }