var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "driv-part-detail-page-carousel" },
    [
      _vm.carouselImages.length
        ? _c(
            "vue-slick-carousel",
            _vm._b(
              {
                key: _vm.carouselImages.length,
                staticClass:
                  "driv-part-detail-page-carousel__images-container noSlickTranslate3d",
                on: { afterChange: _vm.onSlideSelect }
              },
              "vue-slick-carousel",
              _vm.imageSetting,
              false
            ),
            [
              _vm._l(_vm.carouselImages, function(item) {
                return _c("div", { key: item.url, staticClass: "image" }, [
                  _c("img", { attrs: { src: item.url, alt: item.url } })
                ])
              }),
              _vm._v(" "),
              !!_vm.carousel360ImagesSorted.length
                ? _c(
                    "div",
                    { staticClass: "image" },
                    [
                      _c("fmmp-viewer360", {
                        attrs: {
                          amount: _vm.carousel360ImagesSorted.length,
                          orderedImages: _vm.carousel360ImagesSorted
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.allCarouselThumbnails.length
        ? _c(
            "vue-slick-carousel",
            _vm._b(
              { staticClass: "driv-part-detail-page-carousel__nav-container" },
              "vue-slick-carousel",
              _vm.navSettings,
              false
            ),
            _vm._l(_vm.allCarouselThumbnails, function(item, index) {
              return _c(
                "div",
                {
                  key: item.index,
                  class: [
                    "slide",
                    { "selected-slide": _vm.selectedSlide === index }
                  ],
                  on: {
                    click: function() {
                      return _vm.onSlideSelect(index)
                    }
                  }
                },
                [_c("img", { attrs: { src: item.url, alt: item.url } })]
              )
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }