<template>
    <div class="driv-part-detail-page-specifications-container">
        <div class="driv-part-detail-page-tab-data-header">
            {{ partType }} {{ getTranslation("Dimensions") }}
        </div>
        <div
            v-for="(attribute, i) in partAttributes"
            :key="i"
            class="driv-part-detail-page-specification"
        >
            <div class="driv-part-detail-page-specification-label">
                {{ attribute.attribute }}
                <span v-if="attribute.attribute_uom">({{ attribute.attribute_uom }})</span>
            </div>
            <div class="driv-part-detail-page-specification-value">
                {{ attribute.attribute_value }}
            </div>
        </div>
    </div>
</template>

<script>
import { getTranslation } from "../../../../common/partFinderCorporate.helpers";

export default Vue.component("driv-part-detail-page-specifications", {
    props: {
        partAttributes: Array,
        partType: String,
    },
    methods: {
        getTranslation,
    },
});
</script>
