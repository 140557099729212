<template>
    <div v-if="isLoading" class="three-quarters-loader">
        <fmmp-i18n text="loading..." />
    </div>

    <h4 v-else-if="error" class="part-number-page-error">{{ errorText }}</h4>

    <div v-else class="part-number-page-wrapper">
        <h4 class="part-number-page-header">
            <fmmp-i18n text="Search results for:"></fmmp-i18n>
            <span>{{ decodeURIComponent(queryParams.part) }}</span>
        </h4>
        <div class="part-number-page-content">
            <driv-tabs>
                <driv-tab :name="partsTabName" :selected="true" v-if="partsList.products.length">
                    <driv-part-number-result-tab
                        :wtbPath="wtbPath"
                        :anyType="anyType"
                        :products="partsList.products"
                        :detailsPath="detailsPath"
                    ></driv-part-number-result-tab>
                </driv-tab>
                <driv-tab :name="interchangesTabName" v-if="partsList.interchange_products.length">
                    <driv-part-number-result-tab
                        :wtbPath="wtbPath"
                        :anyType="anyType"
                        :products="partsList.interchange_products"
                        isInterchange
                        :detailsPath="detailsPath"
                    ></driv-part-number-result-tab>
                </driv-tab>
            </driv-tabs>
        </div>
    </div>
</template>

<script>
const getTranslation = (value) => {
    return Vue.i18n.get(value);
};

export default Vue.component("driv-part-number-page", {
    data() {
        return {
            queryParams: {
                part: "",
            },
            partsList: {
                interchange_products: [],
                products: [],
            },
            error: null,
            isLoading: true,
        };
    },
    props: {
        wtbPath: {
            type: String,
            default: "",
        },
        interchangesTabName: {
            type: String,
            default: getTranslation("Interchanges"),
        },
        partsTabName: {
            type: String,
            default: getTranslation("Parts"),
        },
        anyType: {
            type: String,
            default: "",
        },
        detailsPath: {
            type: String,
            default: "",
        },
    },
    computed: {
        errorText() {
            return `${getTranslation("Part number")} ${decodeURIComponent(
                this.queryParams.part,
            )} - ${this.error}`;
        },
    },
    created() {
        this.queryParams = window.Qs.parse(window.location.search.slice(1));
        Vue.CatalogApi.CatalogApiService.getByPartNumber(decodeURIComponent(this.queryParams.part))
            .then((resp) => {
                this.partsList = resp;
                if (
                    !this.partsList?.products.length &&
                    !this.partsList?.interchange_products.length
                ) {
                    this.error = getTranslation("Not found");
                } else {
                    this.error = "";
                }
            })
            .catch(() => {
                this.error = getTranslation("Not found");
            })
            .finally(() => {
                this.isLoading = false;
            });
    },
});
</script>
