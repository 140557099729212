<template>
    <div class="applications-subtab commercial-tab">
        <div v-for="(product, i) in tabsData" :key="i" class="product">
            <div class="car__make">
                {{ transformToMissedValue(product.mfr && product.mfr.value) }}
            </div>
            <div class="car__model">
                {{
                    transformToMissedValue(product.equipment_model && product.equipment_model.value)
                }}
            </div>
            <div class="car__years">
                {{
                    transformToMissedValue(
                        product.production_years && displayYearsRange(product.production_years),
                    )
                }}
            </div>
        </div>
    </div>
</template>

<script>
import { transformToMissedValue } from "../../../../common/partFinderCorporate.helpers";
import { displayYearsRange } from "../../../common/partDetailsPage.helpers";

export default Vue.component("driv-part-detail-applications-commercial", {
    props: {
        tabsData: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        transformToMissedValue,
        displayYearsRange,
    },
});
</script>
