<template>
    <div class="driv-part-detail-page-carousel">
        <vue-slick-carousel
            v-if="carouselImages.length"
            class="driv-part-detail-page-carousel__images-container noSlickTranslate3d"
            v-bind="imageSetting"
            :key="carouselImages.length"
            @afterChange="onSlideSelect"
        >
            <div v-for="item in carouselImages" :key="item.url" class="image">
                <img :src="item.url" :alt="item.url" />
            </div>
            <div v-if="!!carousel360ImagesSorted.length" class="image">
                <fmmp-viewer360
                    :amount="carousel360ImagesSorted.length"
                    :orderedImages="carousel360ImagesSorted"
                />
            </div>
        </vue-slick-carousel>

        <vue-slick-carousel
            v-if="allCarouselThumbnails.length"
            class="driv-part-detail-page-carousel__nav-container"
            v-bind="navSettings"
        >
            <div
                v-for="(item, index) in allCarouselThumbnails"
                :key="item.index"
                :class="['slide', { 'selected-slide': selectedSlide === index }]"
                @click="() => onSlideSelect(index)"
            >
                <img :src="item.url" :alt="item.url" />
            </div>
        </vue-slick-carousel>
    </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

const CAROUSEL_REFS = {
    IMAGE: "image",
    NAV: "nav",
};

const DEFAULT_360DEGREE_IMAGE_SRC =
    "/etc.clientlibs/settings/wcm/designs/fmmp-corporate/clientlib-site/resources/images/360degree.svg";

export default Vue.component("driv-part-detail-page-carousel", {
    components: { VueSlickCarousel },
    data() {
        return {
            selectedSlide: 0,
        };
    },
    computed: {
        carousel360ImagesSorted() {
            if (!this.carousel360Images?.length) return [];

            return this.carousel360Images
                .map((imgData) => ({
                    ...imgData,
                    orderInd: imgData.fileName.split(".")[0].match(/[0-9]+$/)[0],
                }))
                .sort((a, b) => a.orderInd - b.orderInd);
        },
        allCarouselThumbnails() {
            const thumbnails = [...this.carouselThumbnails];

            if (this.carousel360Images.length) {
                thumbnails.push({
                    index: this.carouselThumbnails.length + 1,
                    url: DEFAULT_360DEGREE_IMAGE_SRC,
                });
            }

            return thumbnails;
        },
        imageSetting() {
            return {
                ref: CAROUSEL_REFS.IMAGE,
                arrows: false,
                draggable: false,
                swipe: false,
                touchMove: false,
                fade: true,
                initialSlide: this.selectedSlide,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            dots: true,
                            draggable: true,
                            swipe: true,
                            touchMove: true,
                            fade: false,
                        },
                    },
                ],
            };
        },
        navSettings() {
            return {
                ref: CAROUSEL_REFS.NAV,
                asNavFor: null,
                slidesToShow: 4,
                touchMove: false,
                initialSlide: this.selectedSlide,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 870,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                ],
            };
        },
    },
    props: {
        carouselImages: {
            type: Array,
            default: () => [],
        },
        carouselThumbnails: {
            type: Array,
            default: () => [],
        },
        carousel360Images: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        onSlideSelect(id) {
            this.selectedSlide = id;
            this.$refs[CAROUSEL_REFS.IMAGE].goTo(id, false);
        },
    },
});
</script>
